import { Body1, Caption1, Subtitle1, Subtitle4, pointerEvt } from "ms-ui";
import { TextShort } from "src/components/style";
import styled from "styled-components";

interface styledRtCardProps {
  active: boolean;
  dim?: boolean;
  rt: {
    rtCd: string;
    rtId: string;
    title: string;
  };
}
interface IRtCard extends styledRtCardProps {
  onClick?: () => void;
  dataId?: string;
  dataRtId?: string | number;
  dataIdx?: string | number;
  start: {
    time: string;
    name: string;
  };
  end: {
    time: string;
    name: string;
  };
}

export const RtCard = (props: IRtCard) => {
  const { dataId, dataRtId, dataIdx, rt, start, end, active, dim, onClick } =
    props;

  return (
    <>
      <StRtCardLayout
        data-id={dataId && dataId}
        data-key={dataRtId && dataRtId}
        data-idx={dataIdx && dataIdx}
        $rt={rt}
        $active={active}
        $dim={dim && dim}
        onClick={(e) => onClick && onClick()}
      >
        <StRtCardInfo>
          <div style={{ width: "fit-content" }}>
            <StRtTag $ty={rt.rtCd}>
              {rt.rtCd === "b" ? "운행중" : "운행예정"}
            </StRtTag>
          </div>

          <StRtCardInfoTitle>
            S{rt.rtId}{" "}
            <span style={{ color: `var(--colors-gray-100)` }}>|</span>{" "}
            {rt.title}
          </StRtCardInfoTitle>
        </StRtCardInfo>
        <StRtCardStop>
          <StRtCardStopTy>탑승</StRtCardStopTy>
          <StRtCardStopTm>{start.time}</StRtCardStopTm>
          <StRtCardStopNm>{start.name}</StRtCardStopNm>
        </StRtCardStop>
        <StRtCardStop>
          <StRtCardStopTy>하차</StRtCardStopTy>
          <StRtCardStopTm>{end.time}</StRtCardStopTm>
          <StRtCardStopNm>{end.name}</StRtCardStopNm>
        </StRtCardStop>
      </StRtCardLayout>
    </>
  );
};

RtCard.defaultProps = {};

const StRtCardLayout = styled.div<{
  $dim: boolean | undefined;
  $active: boolean;
  $rt: any;
}>`
  height: 140px;
  padding: var(--spacing-16);
  /* box-shadow: var(--box-shadow-500); */
  border-radius: var(--border-radius-5);
  box-shadow: 0 0 0 2px
    ${(p) =>
      p.$active === true
        ? p.$rt.rtCd === "b"
          ? "var(--colors-subblue-500) inset"
          : "var(--colors-mainblue-500) inset"
        : "var(--colors-gray-100) inset"}; //  rtTy, active

  background-color: var(--colors-gray-000);

  position: relative;

  ${(p) =>
    p.$dim === true
      ? `
    &:after {
      content: '';
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
      left: -2px;
      top: -2px;
    }
    `
      : null}

  ${(p) => pointerEvt(p["data-id"])};
`;

const StRtCardInfo = styled.div`
  height: 48px;
  /* padding-bottom: 26px; */
  display: flex;
`;

const StRtCardInfoTitle = styled.div`
  width: fit-content;
  margin-left: var(--spacing-16);
  ${Subtitle1}
`;

const StRtTag = styled.div<{ $ty: string }>`
  color: var(--colors-gray-000);
  background-color: ${(p) =>
    p.$ty === "b" ? `var(--colors-subblue-500)` : "var(--colors-mainblue-500)"};
  border-radius: var(--border-radius-15);
  padding: var(--spacing-4) var(--spacing-12);
  ${Subtitle4}
  white-space: nowrap;
`;

const StRtCardStop = styled.div`
  display: flex;
  margin-bottom: var(--spacing-8);
`;

const StRtCardStopTy = styled.div`
  ${Caption1}

  margin-right: var(--spacing-16);
  color: var(--colors-mainblue-500);
  background-color: var(--colors-mainblue-040);
  border-radius: var(--border-radius-15);

  padding: 3px var(--spacing-12);
  width: fit-content;
  height: 24px;
  white-space: nowrap;
`;

const StRtCardStopTm = styled.div`
  ${Body1}
  width: fit-content;
  margin-right: var(--spacing-8);
  color: var(--colors-gray-300);
`;

const StRtCardStopNm = styled.div`
  ${Body1}
  ${TextShort}
      width: fit-content;
  color: var(--colors-gray-400);
`;
