import { useMemo } from "react";
import { observer } from "mobx-react";
import SwiperStore from "../../../SearchResultList/SwiperStore";

import { KMapStore, Polyline } from "ms-ui";
import { SearchStore } from "src/pages/Search/stores/SearchStore";
import { decodePolyLine } from "src/func/map";

interface Props {
  mapStore: KMapStore;
  searchStore: SearchStore;
  swiperStore: SwiperStore;
}

const PolylineView = ({ mapStore, searchStore, swiperStore }: Props) => {
  const { data } = searchStore;
  const { activeIndex } = swiperStore;

  const decodedPl: [number, number][] | undefined = useMemo(() => {
    const { data } = searchStore;

    if (data && activeIndex < data.length) {
      const activeRt = data[activeIndex];
      if (activeRt) {
        const startPl = decodePolyLine(activeRt.startGeom) || [];
        const movePl = decodePolyLine(activeRt.moveGeom) || [];
        const endPl = decodePolyLine(activeRt.endGeom) || [];
        const decodedPl: [number, number][] = [...startPl, ...movePl, ...endPl];

        return decodedPl;
      }
    }
  }, [data, activeIndex]);

  return (
    <>
      {decodedPl && decodedPl.length > 0 && (
        <Polyline
          map={mapStore.map!}
          path={decodedPl}
          options={{
            strokeColor:
              data?.[activeIndex].rtCd === "b" ? "#4fd3fc" : "#4852E3",
            strokeWeight: 8,
            strokeOpacity: 0.7,
            strokeStyle: "solid",
          }}
        />
      )}
    </>
  );
};

export default observer(PolylineView);
