import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  reaction,
  toJS,
} from "mobx";
import { AxiosResponse } from "axios";
import { parseUrl, stringifyUrl } from "src/func/url";

import {
  addableRoute,
  getNearestPoint,
  sortRoute,
} from "../helper/SearchHelper";

import { MSHUTTLE_DATA } from "src/constants/addr";
import { SearchEvent } from "../evt/SearchEvt";
import { ISearchList } from "src/interface/pages/Search/api";
import searchRepository from "./SearchRepository";
import { URI_FORM } from "src/interface/pages/Search/uri";
import { getErrorMsg } from "src/func/utils";

/**
 * 검색 API 결과
 */
export class SearchStore {
  _data: ISearchList[] | undefined;
  _loading: boolean = false;
  _bound: number;
  _viewType: "start" | "end";

  constructor(qs: URI_FORM) {
    makeObservable(this, {
      _data: observable,
      _loading: observable,
      _bound: observable,
      _viewType: observable,

      data: computed,
      isLoading: computed,
      bound: computed,
      viewType: computed,

      setLoading: action,
      setBound: action,
      setViewType: action,

      search: flow,
      insertLog: flow,
    });

    this._bound = qs.prevView?.bound ? +qs.prevView.bound : 1;
    this._viewType = qs.prevView?.viewType ?? "start";

    reaction(
      () => this._bound,
      (currBound, prevBound) => {
        if (currBound !== prevBound) this.search();
      }
    );
  }

  get data() {
    return toJS(this._data);
  }
  get isLoading() {
    return toJS(this._loading) === true;
  }
  get bound() {
    return toJS(this._bound);
  }
  get viewType() {
    return toJS(this._viewType);
  }

  setLoading(l: boolean) {
    this._loading = l;
  }
  setBound(b: number) {
    this._bound = b;
  }
  setViewType(vt: "start" | "end") {
    this._viewType = vt;
  }

  /**
   * 검색 API
   * @param bound
   */
  *search() {
    try {
      this._loading = true;
      const qs = parseUrl(window.location.search) as URI_FORM;

      if (!qs.start) return;
      if (!qs.end) return;
      // if (qs.time?.length === 0) return;

      qs.start.lat = Number(qs.start.lat);
      qs.start.lng = Number(qs.start.lng);
      qs.end.lat = Number(qs.end.lat);
      qs.end.lng = Number(qs.end.lng);

      const res: AxiosResponse = yield searchRepository
        .getPathListByForm(qs, this._bound)
        .req();

      const { stList, arr } = res.data;
      if (arr) {
        /**
         * arr에 가장 가까운 좌표를 각각 넣어주는 함수
         */
        getNearestPoint(qs, arr, stList);

        /**
         * 가까운순으로 정렬
         */
        const sortedRunn = sortRoute(arr, "b");
        const sortedSchd = sortRoute(arr, "c");
        let sortedResult = [...sortedRunn, ...sortedSchd];

        /**
         * 경로 추가가능한지 여부를 넣어주는 함수
         */
        let sortedAddableResult = addableRoute(sortedResult);
        this._data = sortedAddableResult;

        /**
         * 검색 이벤트로그
         */
        const timeList = MSHUTTLE_DATA.TIME_LIST;

        const { runnCnt, schdCnt }: any = SearchEvent({
          ...qs,
          list: this._data,
          timeInfo: timeList,
        });

        this.insertLog(runnCnt, schdCnt);

        if (qs.recomYn || qs.prevView) {
          const newSearch = {
            ...qs,
          };

          if (qs.recomYn) {
            delete newSearch.recomYn;
          }

          if (qs.prevView) {
            delete newSearch.prevView;
          }
          window.history.replaceState(null, "", `${stringifyUrl(newSearch)}`);
        }

        return sortedAddableResult;
      }
    } catch (e: any) {
      console.log(e);
      this._data = undefined;
      const msg = getErrorMsg(e);
      window.alert(msg);
    } finally {
      this._loading = false;
    }
  }

  /**
   * 검색 로그
   */
  *insertLog(runn_cnt: number, schd_cnt: number) {
    const qs = parseUrl(window.location.search) as URI_FORM;

    if (qs.start) {
      qs.start.lat = Number(qs.start.lat);
      qs.start.lng = Number(qs.start.lng);
    } else {
      delete qs.start;
    }
    if (qs.end) {
      qs.end.lat = Number(qs.end.lat);
      qs.end.lng = Number(qs.end.lng);
    } else {
      delete qs.end;
    }

    if (qs.time?.length === 0) {
      delete qs.time;
    }

    yield searchRepository.insertLog(qs, this._bound, runn_cnt, schd_cnt);
  }
}
