import { action, computed, makeObservable, observable, toJS } from "mobx";
import { FormStore } from "ms-ui";

export class EvtStore extends FormStore<{ rtId: string[]; rtCd: string[] }> {
  _makeView = false;
  constructor() {
    super();

    this._form = {
      rtId: [],
      rtCd: [],
    };

    makeObservable(this, {
      addRtId: action,
      addRtCd: action,
      _makeView: observable,
      makeView: computed,
      setMakeView: action,
      data: computed,
    });
  }

  get makeView() {
    return toJS(this._makeView);
  }

  addRtId = (rtId: string) => {
    const idx = this.form.rtId!.findIndex((c) => c === rtId);

    if (idx === -1) {
      this._form.rtId!.push(rtId);
    }
  };

  addRtCd = (rtCd: string) => {
    const idx = this.form.rtCd!.findIndex((c) => c === rtCd);

    if (idx === -1) {
      this._form.rtCd!.push(rtCd);
    }
  };

  setMakeView = (makeView: boolean) => {
    this._makeView = makeView;
  };

  get data() {
    return {
      makeView: this.makeView,
      rtId: this.form.rtId,
      rtCd: this.form.rtCd,
    };
  }
}
