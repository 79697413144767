import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { KMapStore, Map, getCurrentShowing } from "ms-ui";
import { useURI } from "ms_router";
import { MSHUTTLE_DATA } from "src/constants/addr";

import { URI_FORM } from "src/interface/pages/Search/uri";
import { SearchStore } from "src/pages/Search/stores/SearchStore";
import SwiperStore from "../SearchResultList/SwiperStore";

import Marker from "./views/Marker";
import CustomOverlays from "./views/CustomOverlays";
import Polyline from "./views/Polyline";
import { SDKLoadFailed } from "src/components/NoMap";

interface Props {
  mapStore: KMapStore;
  searchStore: SearchStore;
  swiperStore: SwiperStore;
  formType: "RESULT" | "FAKE";
}

const MapContainer = ({
  mapStore,
  searchStore,
  swiperStore,
  formType,
}: Props) => {
  const { getQuery } = useURI();
  const form = getQuery<URI_FORM>();
  const { map } = mapStore;
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [isDragging, setDragging] = useState(false);

  /**
   * 카카오맵 바운드가 바뀔 때마다 출발/도착지로보기 버튼 체크
   */
  const eventHandler = useCallback(
    (e: string, obj: any) => {
      if (!mapStore.map) return;

      if (e === "drag") setDragging(true);
      if (e === "dragend") setDragging(false);

      if (e === "bounds_changed") {
        if (searchStore.data) {
          const viewType = getCurrentShowing(
            searchStore.data!,
            mapStore.map.getBounds()
          );
          if (viewType !== "") searchStore.setViewType(viewType);
        }
        if (isDragging === false) {
          mapStore.setLevel(obj.level);
          const hidden = new Promise((res, rej) => {
            setOverlayVisible(false);
            res(true);
          });

          hidden.then((res) => {
            setTimeout(() => {
              setOverlayVisible(true);
            }, 10);
          });
        }
      }

      if (e === "zoom_changed") {
        mapStore.setLevel(obj.level);
        const hidden = new Promise((res, rej) => {
          setOverlayVisible(false);
          res(true);
        });

        hidden.then((res) => {
          setTimeout(() => {
            setOverlayVisible(true);
          }, 10);
        });
      }
    },
    [isDragging]
  );

  /**
   * 결과가 없을 경우 맵 액션을 막는다
   */
  useEffect(() => {
    if (
      formType !== "RESULT" &&
      map &&
      (!searchStore.data || searchStore.data.length === 0)
    ) {
      mapStore.setDraggable(false);
      mapStore.setZoomable(false);
    } else {
      mapStore.setZoomable(true);
      mapStore.setDraggable(true);
    }
  }, [formType, map, searchStore.data]);

  return (
    <>
      <Map
        mapStore={mapStore}
        options={{
          center: {
            lat: form?.[`start`]?.lat ?? 37.6025229689525,
            lng: form?.[`start`]?.lng ?? 127.01100737843952,
          },
          level: 4,
        }}
        appKey={MSHUTTLE_DATA.KAKAO_JS}
        callback={eventHandler}
        width="100%"
        height="100%"
      >
        {!mapStore.hasMapSdk() && <SDKLoadFailed />}
        {map && (
          <>
            <Marker mapStore={mapStore} />
            <Polyline
              mapStore={mapStore}
              searchStore={searchStore}
              swiperStore={swiperStore}
            />
            <CustomOverlays
              mapStore={mapStore}
              searchStore={searchStore}
              swiperStore={swiperStore}
              overlayVisible={overlayVisible}
            />
          </>
        )}
      </Map>
    </>
  );
};

export default observer(MapContainer);
