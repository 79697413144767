import styled from "styled-components";

interface styledTtWpProps {
  $active: boolean;
}

export interface IToolTipWrapper {
  children: any;
  active: boolean;
}

export const ToolTipWrapper = (props: IToolTipWrapper) => {
  const p = props.active === true ? "fadeIn" : "fadeOut";
  return (
    <StyledTtWp $active={props.active} className={`tooltip-wrapper ` + p}>
      {props.children}
    </StyledTtWp>
  );
};

const StyledTtWp = styled.div<styledTtWpProps>`
  will-change: transform;
  opacity: 0;
  transform: translate3d(0, 20px, 0);
  z-index: -1;

  &.fadeIn {
    opacity: 1;
    transition: all 0.5s ease-out;
    transition-delay: 0.3s;
    transform: translate3d(0, 0px, 0);
  }

  &.fadeOut {
    transition: all 0.5s ease-out;
    opacity: 0;
    height: 0;
    overflow: hidden;
  }
`;
