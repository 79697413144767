import { Toast } from "ms-ui";

interface Props {
  type: "start" | "end";
}

const ToastManager = ({ type }: Props) => {
  return (
    <Toast dur={3000}>
      최대한 가까이{" "}
      {type === "start" ? "모시러 갈수 있도록" : "모셔드릴 수 있도록"}
      <br />
      지도를 움직여 정확한 {type === "end" ? "도착지" : "출발지"}를 지정해
      주세요.
    </Toast>
  );
};

export default ToastManager;
