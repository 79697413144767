import { observer } from "mobx-react";
import { useEffect } from "react";
import { SearchStore } from "../../stores/SearchStore";

import SwiperStore from "./views/SearchResultList/SwiperStore";
import {
  impressSearchResultMake,
  impressSearchResultRt,
} from "../../helper/EvtHelper";
import { EvtStore } from "../../stores/EvtStore";
import { useURI } from "ms_router";
import { stringifyUrl } from "src/func/url";

interface Props {
  searchStore: SearchStore;
  swiperStore: SwiperStore;
  evtStore: EvtStore;
}
/*
  bfCache를 는 사파리, 파이어폭스, 요즘에 크롬(몇몇의 안드로이드에서) 지원하는 중이라고 함.
  bfCache를 사용하는 브라우저의 경우 페이지 이탈 시 pagehide 이벤트가 발생됨
  보고있던 정보들이 bfCache에 남아있어서 돌아오면 그대로 나오므로 beforeLeave 함수를 쓸 필요 없음.
*/
const ExitHandler = ({ searchStore, swiperStore, evtStore }: Props) => {
  const { getQuery, changeQuery } = useURI();

  /**
   *
   */
  const exitEvt = () => {
    const rtYn = (searchStore.data || []).length > 0 ? 1 : 0;

    if (rtYn === 1) {
      impressSearchResultRt({
        rtCd: evtStore.data.rtCd,
        rtId: evtStore.data.rtId,
      });
    }

    if (evtStore.makeView === true) {
      impressSearchResultMake(rtYn);
    }
  };

  //이탈 핸들러
  const ExitEventHandler = () => {
    const q = getQuery<any>();

    console.log("q.prevView: ", q.prevView);

    if (!q.prevView) {
      const newSearch = {
        ...q,
        prevView: {
          activeIdx: swiperStore.activeIndex,
          bound: searchStore.bound,
          viewType: searchStore.viewType,
        },
      };

      console.log("newSearch: ", newSearch);
      window.history.replaceState(null, "", `${stringifyUrl(newSearch)}`);

      exitEvt();
    }
  };

  useEffect(() => {
    return () => {
      console.log("cleanup");
      ExitEventHandler();
    };
  }, []);

  //페이지 이탈 + 새로고침
  useEffect(() => {
    //no bfCache 브라우저에서는 unload가 됨
    const beforeUnloadListener = () => {
      console.log("beforeUnload");
      ExitEventHandler();
    };
    window.addEventListener("beforeunload", beforeUnloadListener);

    //cleanup
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadListener);
    };
  }, []);

  //아이폰은 pageHide
  useEffect(() => {
    const pageHideListener = () => {
      console.log("pagehide");
      ExitEventHandler();
      searchStore.setLoading(false);
    };
    window.addEventListener("pagehide", pageHideListener);

    return () => window.removeEventListener("pagehide", pageHideListener);
  }, []);

  return null;
};

export default observer(ExitHandler);
