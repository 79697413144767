import { observer } from "mobx-react";

import styled from "styled-components";

import { parseUrl } from "src/func/url";

import Input from "./views/Input";
import { ITime } from "ms_data";
import { MSHUTTLE_DATA } from "src/constants/addr";

import { Up, pointerEvt } from "ms-ui";
import { getEndTime, getStartTime } from "src/pages/Search/helper/TimeHelper";
import { useURI } from "ms_router";
import { searchresultEvtId } from "src/constants/evt/search";
import { URI_FORM } from "src/interface/pages/Search/uri";

interface Props {
  formType: "RESULT" | "FAKE";
  changeFormType: () => void;
}

const ResultForm = ({ formType, changeFormType }: Props) => {
  const { history, location } = useURI();
  const form = parseUrl(location.search) as URI_FORM;

  const timeList = MSHUTTLE_DATA.TIME_LIST;

  /**
   * 승/하차지 or 시간 선택 폼으로 이동
   */
  const goForm = async (type: "start" | "end" | "time") => {
    history.push({
      ...location,
      pathname:
        type === "start"
          ? "/search/start_form"
          : type === "end"
          ? "/search/end_form"
          : type === "time"
          ? "/search/select_time"
          : "/search/end_form",
    });
  };

  /**
   * 보여질 텍스트 가공
   */
  const getTimeText = (tlist: ITime[], selectList: string[]) => {
    const slength = selectList.length;
    const newTimelist = tlist.map((item) => ({ ...item }));
    const selected = newTimelist.filter(
      (item) => selectList.indexOf(item.timeId) !== -1
    );

    return slength ? (
      slength === newTimelist.length || slength === 0 ? (
        "전체시간"
      ) : slength === 1 ? (
        selected[0].timeSearch
      ) : (
        <div>
          {getStartTime(selected[0])} ~{" "}
          {getEndTime(selected[selected.length - 1])}
        </div>
      )
    ) : (
      "시간을 입력하세요"
    );
  };

  return (
    <>
      {formType === "RESULT" && (
        <StSearchFormGroup>
          <StSearchFormGroupInput>
            {form.start && (
              <Input
                dataId={"click_search_updateStart"}
                onClick={() => goForm("start")}
                title={form.start.title || `도착지를 입력하세요.`}
                confirm={form.start !== undefined}
                type={"start"}
              />
            )}
            {form.end && (
              <Input
                dataId={"click_search_updateEnd"}
                onClick={() => goForm("end")}
                title={form.end.title || `출발지를 입력하세요.`}
                confirm={form.end !== undefined}
                type={"end"}
              />
            )}
            {form.time && timeList && (
              <Input
                dataId={"click_search_updateTime"}
                onClick={() => goForm("time")}
                title={
                  form.time
                    ? getTimeText(timeList, form.time)
                    : "시간을 입력하세요"
                }
                confirm={form.time !== undefined}
                type={"time"}
              />
            )}
          </StSearchFormGroupInput>
          <StSearchFormUpBtn
            onClick={changeFormType}
            data-id={"click_search_updateCancel"}
            // id={searchresultEvtId["btn_change-cancel"].key}
          >
            <Up />
          </StSearchFormUpBtn>
        </StSearchFormGroup>
      )}
    </>
  );
};

export default observer(ResultForm);

const StSearchFormGroup = styled.section`
  z-index: 10;
  position: absolute;
  top: var(--spacing-24);
  left: 50%;
  width: 100%;
  max-width: calc(100% - 2 * var(--spacing-16));
  transform: translate(-50%, 0);

  button {
    margin-top: var(--spacing-12);
  }
`;

const StSearchFormGroupInput = styled.div`
  width: 100%;
  border-radius: var(--border-radius-5);
  box-shadow: var(--box-shadow-400);
`;

const StSearchFormUpBtn = styled.div`
  /* top: 0; */
  bottom: calc(-1 * var(--spacing-12));
  position: absolute;
  left: 50%;
  cursor: pointer;
  transform: translate(-50%, 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--colors-gray-000);
  box-shadow: var(--box-shadow-500);
  border: none;
  width: 42px;
  height: 42px;
  border-radius: 21px;

  ${(p) => pointerEvt(p["data-id"])};
`;
