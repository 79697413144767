import styled from "styled-components";

import { Body2, Button, Subtitle3 } from "ms-ui";
import { searchresultEvtId } from "src/constants/evt/search";

interface Props {
  active: boolean;
  goMake: () => void;
}

const CustomCardNoti = ({ active, goMake }: Props) => {
  return (
    <EtcCardLayer>
      <EtcCard
        $active={active}
        onClick={goMake}
        // data-id={"card_make_result"}
        // id={searchresultEvtId.card_make_result.key}
      >
        <EtcCardContent>
          <span>알림받기</span>
          를 하면 검색하신 정보로 <br />
          맞춤 출근길을 만들어 드려요!
        </EtcCardContent>

        <Button
          color={`primary`}
          size="rg"
          radius={"5"}
          width="100%"

          // id={searchresultEvtId.card_make_result.key}
        >
          내 출근길 알림받기
        </Button>
      </EtcCard>
    </EtcCardLayer>
  );
};

export default CustomCardNoti;

const EtcCardLayer = styled.div`
  width: 100%;

  & > * :not(button) {
    pointer-events: none;
  }
`;

const EtcCard = styled.div<{ $active: boolean }>`
  background-color: var(--colors-gray-000);
  box-shadow: 0 0 0 2px
    ${(p) =>
      p.$active === true
        ? `var(--colors-mainblue-500) inset`
        : `var(--colors-gray-100) inset`};
  border-radius: var(--border-radius-5);

  height: 140px;
  padding: var(--spacing-16);
`;

const EtcCardContent = styled.div`
  ${Body2}
  color: var(--colors-gray-500);
  text-align: center;
  margin-bottom: var(--spacing-12);
  span {
    color: var(--colors-red-500);
    ${Subtitle3}
  }
`;
