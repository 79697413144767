import { action, computed, makeObservable, observable, toJS } from "mobx";
import { URI_FORM } from "src/interface/pages/Search/uri";

class SwiperStore {
  constructor(qs: URI_FORM) {
    makeObservable(this, {
      _data: observable,
      data: computed,
      swiper: computed,
      hasSwiper: computed,
      isTouching: computed,
      previousIndex: computed,
      activeIndex: computed,
      realIndex: computed,

      setSwiper: action,
      destroySwiper: action,
      initStore: action,
      slideTo: action,
      setRealIndex: action,
      setActiveIndex: action,
      setIsTouching: action,
    });

    this._data.activeIndex = qs.prevView?.activeIdx
      ? +qs.prevView.activeIdx
      : 0;
  }

  _data = {
    swiper: undefined as any,
    isTouching: false,
    previousIndex: 0,
    activeIndex: 0,
    realIndex: 0,
  };

  get data() {
    return toJS(this._data);
  }

  get hasSwiper(): boolean {
    return toJS(this._data.swiper && !this._data.swiper.destroyed);
  }

  destroySwiper() {
    if (this._data.swiper && !this._data.swiper.destroyed) {
      this._data.swiper.destroy();
      this._data.swiper = undefined;
      this._data.activeIndex = 0;
      this._data.realIndex = 0;
      this._data.activeIndex = 0;
    }
  }

  initStore() {
    this._data = {
      swiper: undefined as any,
      isTouching: false,
      previousIndex: 0,
      activeIndex: 0,
      realIndex: 0,
    };
  }

  setSwiper(swiper: any) {
    this._data.swiper = swiper;
  }

  slideTo(idx: number) {
    console.log(idx);
    if (this._data.swiper && !this._data.swiper.destroyed) {
      this._data.swiper?.slideTo(idx, 300);
      this._data.realIndex = idx;
      this._data.activeIndex = idx;
    }
    //터칭 풀기
    this._data.isTouching = false;
  }

  setRealIndex(idx: number) {
    this._data.realIndex = idx;
  }

  setActiveIndex(idx: number) {
    this._data.activeIndex = idx;
  }

  setIndex(idx: number) {
    this._data.realIndex = idx;
    this._data.activeIndex = idx;
  }

  setIsTouching(bool: boolean) {
    this._data.isTouching = bool;
  }

  get swiper() {
    return toJS(this._data.swiper);
  }

  get previousIndex() {
    return toJS(this._data.previousIndex);
  }

  get activeIndex() {
    return toJS(this._data.activeIndex);
  }

  get realIndex() {
    return toJS(this._data.realIndex);
  }

  get isTouching() {
    return toJS(this._data.isTouching);
  }
}

export default SwiperStore;
