import axios, { AxiosResponse, CancelTokenSource } from "axios";
import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  reaction,
  toJS,
} from "mobx";
import { changeQueryString, resToSpot } from "../helper/TmapHelper";
import { parseUrl } from "src/func/url";
import {
  TMAP_ADDRESS,
  TMAP_ADDRESS_ERROR,
  TMAP_POI,
} from "src/interface/pages/Search/tmap";
import { URI_FORM } from "src/interface/pages/Search/uri";
import TMapRepository from "src/repository/External/TMapRepository";
import { ISpot } from "src/interface/pages/Search/spot";

export class TMapStore {
  _navigate: any;
  /**
   * 검색어
   */
  _text: string = "";

  /**
   * 검색 위치
   */
  _spot = {
    lat: 0,
    lng: 0,
  };

  _spotInfo: ISpot | undefined = undefined;

  _loading: boolean = false;

  /**
   * 검색결과
   * poi: 검색어를 통한 검색 결과
   * geo: 위치를 통한 검색 결과
   */
  _response: {
    poi: TMAP_POI[];
    geo: TMAP_ADDRESS | undefined;
  } = {
    poi: [],
    geo: undefined,
  };
  _cancelToken: CancelTokenSource | undefined = undefined;

  constructor(navigate: any, initialValue?: string | ISpot) {
    this._navigate = navigate;

    if (initialValue) {
      if (typeof initialValue === "string") {
        this._text = initialValue;
      } else {
        this._spot.lat = initialValue.lat;
        this._spot.lng = initialValue.lng;
        this._spotInfo = initialValue;
      }
    }

    makeObservable(this, {
      _text: observable,
      _spot: observable,
      _spotInfo: observable,
      _loading: observable,
      _response: observable,

      text: computed,
      spot: computed,
      spotInfo: computed,
      loading: computed,
      response: computed,
      isChangedPosition: computed,

      setText: action,
      setSpot: action,
      setSpotInfo: action,
      setLoading: action,

      getAddrList: flow,
      getAddrListByGeo: flow,
    });

    /**
     * 검색어가 바뀔 때 마다 TMap POI API에 요청
     */
    reaction(
      () => this._text,
      (curr, prev) => {
        if (curr === "") return (this._text = curr);
        if (this._cancelToken)
          this._cancelToken.cancel("operation cancelled due to new request");

        this._cancelToken = axios.CancelToken.source();

        this.getAddrList(curr, this._cancelToken);
      }
    );

    /**
     * 검색 위치가 바뀔 때마다 TMap ReverseGeo API에 요청
     */
    reaction(
      () => this._spot,
      (curr, prev) => {
        this.getAddrListByGeo(curr.lat, curr.lng);
      }
    );
  }

  get text() {
    return toJS(this._text);
  }
  get spot() {
    return toJS(this._spot);
  }

  get spotInfo() {
    return toJS(this._spotInfo);
  }

  get loading() {
    return toJS(this._loading);
  }
  get response() {
    return toJS(this._response);
  }

  /**
   * 검색 위치와 선택한 위치가 같은지 체크
   */
  get isChangedPosition() {
    let isChanged = false;

    const form = parseUrl(window.location.search) as URI_FORM;
    const type = window.location.pathname.includes("start_map")
      ? "start"
      : "end";

    //현재 위치
    const spot = form[`${type}`];
    //검색 위치
    const search = form.search;

    if (spot && search) {
      if (+spot.lat !== +search.lat || +spot.lng !== +search.lng) {
        isChanged = true;
      }
    }

    return isChanged;
  }

  setText(t: string) {
    this._text = t;
  }
  setSpot(lat: number, lng: number) {
    this._spot = {
      lat,
      lng,
    };
  }
  setSpotInfo(spotInfo: ISpot) {
    this._spotInfo = spotInfo;
  }

  setLoading(l: boolean) {
    this._loading = l;
  }

  //티맵POI
  *getAddrList(query: any, cancelToken: CancelTokenSource) {
    try {
      this._loading = true;
      const res: AxiosResponse = yield TMapRepository.POI(query, cancelToken);
      if (res?.status === 200) {
        const list = res.data?.searchPoiInfo?.pois?.poi || [];
        const result: TMAP_POI[] = [];
        let cnt = 0;

        list.forEach((point: TMAP_POI) => {
          if (cnt > 7) return false;
          if (point.zipCode !== "") {
            //검색결과
            result.push(point);

            //결과 수 ++;
            cnt++;
          }
        });

        this._response.poi = result;
      } else if (res?.status === 204) {
        this._response.poi = [];
      }
    } catch (e) {
      console.log(e);
    } finally {
      this._loading = false;
    }
  }

  //티맵reverseGEO
  *getAddrListByGeo(lat: number, lng: number, cancelToken?: CancelTokenSource) {
    let spotInfo: ISpot = {
      title: "",
      address: "검색결과 없음",
      lat,
      lng,
    };

    try {
      this._loading = true;
      const res: AxiosResponse<{
        addressInfo?: TMAP_ADDRESS;
        error?: TMAP_ADDRESS_ERROR;
      }> = yield TMapRepository.ReverseGeo(lat, lng, cancelToken);
      console.log("res: ", res);

      if (res.status === 200 && res.data && res.data.addressInfo) {
        this._response.geo = res.data.addressInfo;
        spotInfo = resToSpot(lat, lng, res.data.addressInfo);
        this._spotInfo = spotInfo;
      }

      changeQueryString(this._navigate, spotInfo);
    } catch (e) {
      console.log(e);
      changeQueryString(this._navigate, spotInfo);
    } finally {
      this._loading = false;
    }
  }
}
