import { useState } from "react";

import styled, { css } from "styled-components";
import { ITime } from "ms_data";
import {
  Body1,
  Body2,
  BottomFixed,
  Button,
  Nav,
  Subtitle2,
  contents,
} from "ms-ui";

import { naviEvtId } from "src/constants/evt/nav";
import { BackHistory, parseUrl, stringifyUrl } from "src/func/url";

import { MSHUTTLE_DATA } from "src/constants/addr";
import { useURI } from "ms_router";
import { URI_FORM } from "src/interface/pages/Search/uri";
import { searchtimeEvtId } from "src/constants/evt/search";
import { CheckSvg } from "./Check";

const NewSearchTimeList = () => {
  const { navigate, getQuery, setQuery } = useURI();
  const form = getQuery<URI_FORM>();
  const timeList = MSHUTTLE_DATA.TIME_LIST; //시간정보

  const [checkedList, setCheckedList] = useState(form.time || []);

  const isActive = (item: ITime) => checkedList.indexOf(item.timeId) > -1;

  /**
   * 선택된 시간을 qs에 저정한 뒤, 다음 폼으로 이동
   */
  const submit = () => {
    if (checkedList.length > 0) {
      const newForm = parseUrl<URI_FORM>(window.location.search);
      newForm.time = checkedList;

      navigate(`/search/select_path_map${stringifyUrl(newForm)}`);
    }
  };

  /**
   * 시간 선택
   */
  const selectTime = (item: ITime) => {
    const tempCheckedList = [...checkedList];
    if (isActive(item) === true) {
      const k = tempCheckedList.findIndex((ci) => ci === item.timeId);
      tempCheckedList.splice(k, 1);
    } else {
      tempCheckedList.push(item.timeId);
    }
    setCheckedList(tempCheckedList);
  };

  return (
    <>
      <Nav
        title="도착 시간 선택"
        left={{
          type: "back",
          onClick: () => BackHistory(navigate, "/"),
          // id: naviEvtId.navi_back.key,
        }}
      />
      <StTimeCheck>
        <>
          <StTime>
            <StCommentWrapper>
              <StTimeContent01>
                원하는 도착시간을 선택해 주세요.
              </StTimeContent01>
              <StTimeContent02>
                도착시간은 마지막 하차지 기준입니다.
              </StTimeContent02>
            </StCommentWrapper>

            {timeList &&
              timeList.map((c, i) => (
                <StTimeBtn
                  width="100%"
                  size={"sm"}
                  key={i}
                  data-id={
                    isActive(c) === true ? "unselect_time" : "select_time"
                  }
                  data-idx={i}
                  data-key={c.timeId}
                  outline={true}
                  onClick={() => selectTime(c)}
                  $checked={isActive(c)}
                >
                  <StTimeLabelTxt>{`${c.timeSearch} 도착`}</StTimeLabelTxt>
                  {isActive(c) && <CheckSvg key={i} checked={true} />}
                </StTimeBtn>
              ))}
            <div style={{ width: "100%", height: "50px" }}></div>
          </StTime>
        </>
      </StTimeCheck>
      <BottomFixed>
        <Button
          radius={"0"}
          width="100%"
          color={`primary`}
          size="rg"
          disabled={timeList === null || checkedList.length === 0}
          onClick={() => submit()}
          data-id="search_selectTime"
          // id={searchtimeEvtId.btn_select_time_active.key}
        >
          확인
        </Button>
      </BottomFixed>
    </>
  );
};

export default NewSearchTimeList;

const StTimeCheck = styled.div`
  width: 100%;
  height: calc(100dvh - 56px);

  background-color: #fff;
  touch-action: manipulation;
`;

const StTimeBtn = styled(Button)<{ $checked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  margin-bottom: 12px;

  /* @media (hover: hover) and (pointer: fine) {
    .hover\:underline:hover {
      text-decoration-line: underline;
    }
  } */

  ${(p) =>
    !p.$checked &&
    css`
      box-shadow: 0 0 0 1px var(--contents-tertiary) inset !important;
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          box-shadow: 0 0 0 1px var(--contents-description) inset !important;
        }
      }
      > * {
        color: ${contents.description.value} !important;
      }
    `}

  ${(p) =>
    p.$checked &&
    css`
      > * {
        color: var(--foundation-normal-primary) !important;
      }
    `}
`;

const StTimeLabelTxt = styled.span`
  ${Body1}
  cursor: pointer;
  margin-left: var(--spacing-4);
  color: var(--colors-gray-400);
`;

const StTime = styled.div`
  /* padding: var(--spacing-32) var(--spacing-16) 0; */
  padding: var(--spacing-32) var(--spacing-16) var(--spacing-16)
    var(--spacing-16);
  overflow-y: auto;
  height: 100%;
  /* height: calc(100vh - 106px); */
`;

const StTimeContent01 = styled.div`
  ${Subtitle2}
`;

const StTimeContent02 = styled.div`
  ${Body1}
  color: var(--colors-gray-300);
`;

const StCommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 16px;
`;
