//서버 통신 관련 함수를 모아놓은 클래스, store에서 사용
import axios, { CancelTokenSource } from "axios";
import { MSHUTTLE_DATA } from "src/constants/addr";

class tmapRepository {
  //명칭으로 상세 검색
  POI(query: any, cancelToken: CancelTokenSource) {
    const params: any = {
      resCoordType: "WGS84GEO",
      reqCoordType: "WGS84GEO",
      searchKeyword: query,
      appKey: MSHUTTLE_DATA.TMAP,
    };

    try {
      return axios({
        method: "GET",
        url: `https://apis.openapi.sk.com/tmap/pois?version=1&format=json&callback=result`,
        params,
        cancelToken: cancelToken.token,
      });
    } catch (error) {}
  }

  //위도 경도로 상세 검색
  ReverseGeo(lat: number, lng: number, cancelToken?: CancelTokenSource) {
    const params: any = {
      coordType: "WGS84GEO",
      addressType: "A00",
      lon: lng,
      lat,
      appKey: MSHUTTLE_DATA.TMAP,
    };

    return axios({
      method: "GET",
      url: `https://apis.openapi.sk.com/tmap/geo/reversegeocoding?version=1&format=json&callback=result`,
      params,
      cancelToken: cancelToken?.token,
    });
  }
}

export default new tmapRepository();
