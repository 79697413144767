import StaImg from "src/pages/Search/img/s_marker_bottom_2.svg";
import EndImg from "src/pages/Search/img/e_marker_bottom_2.svg";
import Check from "src/pages/Search/img/check.svg";
import styled from "styled-components";

interface Props {
  type: "start" | "end";
  confirm: boolean;
}

export const Marker = (props: Props) => {
  const { type, confirm } = props;
  return (
    <StSearchMarker>
      <StSearchMarkerType type={type}>
        <StSearchMarkerInner>
          {confirm ? (
            <StSearchMarkerIcon>
              <img src={Check} alt="" />
            </StSearchMarkerIcon>
          ) : (
            <StSearchMarkerTxt>
              {type === "start" ? "출발" : null}
              {type === "end" ? "도착" : null}
            </StSearchMarkerTxt>
          )}
        </StSearchMarkerInner>
      </StSearchMarkerType>
      <StSearchMarkerBottom
        src={`${type === "start" ? StaImg : EndImg}`}
        alt="마커하단"
        className="marker__bottom"
      />
    </StSearchMarker>
  );
};

const StSearchMarker = styled.div`
  position: absolute;
  width: 60px;
  height: 77px;
  z-index: 100;
  transform: translate3d(-50%, -100%, 0);
  left: 50%;
  top: 50%;

  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const StSearchMarkerType = styled.div<{ type: string }>`
  position: relative;
  width: 60px;
  height: 60px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 39px;
  transform: translate3d(0, 0, 0);
  transition: max-width ease-out 0.2s;

  ${(p) =>
    p.type === "start"
      ? `background: linear-gradient(to right, var(--colors-green-400) 0%, var(--colors-green-400) 50%, var(--colors-green-500) 50%, var(--colors-green-500) 100%);`
      : null}
  ${(p) =>
    p.type === "end"
      ? `background: linear-gradient(to right, var(--colors-red-500) 0%, var(--colors-red-500) 50%, var(--colors-red-600) 50%, var(--colors-red-600) 100%);`
      : null}
`;

const StSearchMarkerInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  z-index: 20;
  margin: 9px;
  // padding: 8px 12px;
  max-width: 101px;
  width: 100%;
  height: 38px;
  background-color: var(--colors-gray-000);
  border-radius: 28px;
  font-size: 14px;
  font-weight: 700;

  transition: max-width ease 0.2s;
`;

const StSearchMarkerBottom = styled.img`
  position: absolute;
  z-index: 10;
  bottom: -1px;
  left: 50%;
  transform: translate3d(-50%, 0%, 0);
`;

const StSearchMarkerTxt = styled.div``;

const StSearchMarkerIcon = styled.div`
  display: block;
  top: calc(50% + 3px);
`;
