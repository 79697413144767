import { useEffect, useState } from "react";
import { observer } from "mobx-react";

import styled from "styled-components";

import { parseUrl, stringifyUrl } from "src/func/url";
import { getLS, setLS } from "src/func/storage";
import Top from "./views/Top";
import SMap from "./views/Map";
import ToastManager from "./views/Toast/ToastManager";

import { TMapStore } from "../../stores/TMapStore";
import { Button, KMapStore, ModalContainer, spacing } from "ms-ui";
import { useURI } from "ms_router";
import { URI_FORM } from "src/interface/pages/Search/uri";

import { ISearchData } from "src/interface/pages/Search/spot";
import { isNil } from "ms_data";

const SearchMap = () => {
  const { location, navigate, getQuery } = useURI();
  const query = parseUrl<URI_FORM>(window.location.search);

  const type = location.pathname.includes("start_map") ? "start" : "end";

  const [confirm, setConfirm] = useState(false);

  const [tmapStore] = useState(() => new TMapStore(navigate, query[`${type}`]));
  const [mapStore] = useState(() => new KMapStore());

  let tick: any;

  useEffect(() => {
    return () => clearTimeout(tick);
  }, []);

  const id =
    type === "start"
      ? "search_selectStart"
      : type === "end"
      ? "search_selectEnd"
      : undefined;

  /**
   * 다음 페이지로
   */
  const goNext = () => {
    if (tmapStore.loading !== true) {
      const query = parseUrl<URI_FORM>(window.location.search);
      //pathname 설정
      let pathname = "/search/select_time";
      if (!query.start) {
        pathname = "/search/start_form";
      } else if (!query.end) {
        pathname = "/search/end_form";
      } else if (!isNil(query.time)) {
        pathname = "/search/select_path_map";
      }

      /** 마커 checked */
      setConfirm(true);

      try {
        tmapStore.setLoading(true);

        /** 최근 검색어 저장 */
        saveSearchdata(type);

        /** 검색 결과 이벤트 트래킹 */
        // const initialSearchedData = query.search;
        // clickSearchSelect(
        //   initialSearchedData,
        //   {
        //     ...query[`${type}`]!,
        //     legalDongCode: tmapStore.response.geo?.legalDongCode,
        //   },
        //   mapStore.getData.mapOption.level,
        //   type
        // );

        //search 설정

        tick = setTimeout(() => {
          let search = parseUrl(window.location.search) as URI_FORM;
          delete search.search;

          navigate({
            pathname,
            search: stringifyUrl(search),
          });
        }, 500);
      } catch (e) {
        console.log("e: ", e);
      } finally {
        tmapStore.setLoading(false);
      }
    }
  };

  /**
   * 저장할 data 구하기
   * currSpot: 현재위치
   * searchSpot: 검색 위치
   */
  const getDataToSave = (
    currSpot: URI_FORM["start"] | URI_FORM["end"],
    searchSpot: ISearchData
  ) => {
    //검색위치와 현재위치가

    //같다면
    let dataToSave: ISearchData = {
      title: searchSpot!.title,
      address: searchSpot!.address,
      lat: +searchSpot!.lat,
      lng: +searchSpot!.lng,
      /**
       * 최근검색어를 누르고 들어오면 둘 중 뭐가 있을지 모름
       */
      ...(searchSpot!.zipCode && { zipCode: searchSpot!.zipCode }),
      ...(searchSpot!.legalDongCode && {
        legalDongCode: searchSpot!.legalDongCode,
      }),
    };

    //다르다면
    if (tmapStore.isChangedPosition) {
      dataToSave = {
        title: currSpot!.title,
        address: currSpot!.address,
        lat: +currSpot!.lat,
        lng: +currSpot!.lng,
        legalDongCode: tmapStore.response.geo?.legalDongCode,
      };
    }
    return dataToSave;
  };

  /**
   * 검색결과를 localStorage에 저장
   * @param type
   */
  const saveSearchdata = (type: "start" | "end") => {
    const form = parseUrl<URI_FORM>(window.location.search);
    const currSpot = form[`${type}`];
    const searchSpot = form.search;

    if (currSpot && searchSpot) {
      let dataToSave: ISearchData = getDataToSave(currSpot, searchSpot);

      const target = `${type}SearchHistory`;
      const targetData = getLS(target);

      if (targetData) {
        const storageArray: ISearchData[] = JSON.parse(targetData);
        const SAME_HISTORY_OBJ_IDX = storageArray.findIndex(
          (obj) => +obj.lat === +dataToSave.lat && +obj.lng === +dataToSave.lng
        );

        //이미 검색했던 좌표라면
        if (SAME_HISTORY_OBJ_IDX !== -1) {
          storageArray.splice(SAME_HISTORY_OBJ_IDX, 1);
          const newHistoryArr = [dataToSave, ...storageArray];
          setLS(target, newHistoryArr);
        } else {
          if (storageArray.length > 4) {
            storageArray.pop();
          }
          storageArray.reverse();
          storageArray.push(dataToSave);
          storageArray.reverse();
          setLS(target, storageArray);
        }
      } else {
        setLS(target, [dataToSave]);
      }
    }
  };

  return (
    <ModalContainer>
      {/* top */}
      <Top tmapStore={tmapStore} />

      {/* map */}
      <SMap
        type={type}
        mapStore={mapStore}
        tmapStore={tmapStore}
        confirm={confirm}
      />

      {/* <Toast /> */}
      <ToastManager type={type} />

      {/* btn */}
      <MapBtn>
        <BtnWrapper>
          <Button
            width="100%"
            data-id={id && id}
            // id={id && id}
            radius={"5"}
            disabled={
              !query[type] ||
              query[type]?.lat === 0 ||
              query[type]?.lng === 0 ||
              query[type]?.title === ""
            }
            color={type === "start" ? "positive" : "negative"}
            size={`rg`}
            onClick={goNext}
          >
            {type === "start" ? "출발" : null}
            {type === "end" ? "도착" : null}
            지로 선택
          </Button>
        </BtnWrapper>
      </MapBtn>
    </ModalContainer>
  );
};

export default observer(SearchMap);

export const MapBtn = styled.div`
  width: 100%;
  bottom: var(--spacing-24);
  position: absolute;
  z-index: 1;
`;

const BtnWrapper = styled.div`
  position: relative;
  padding: 0 ${spacing[16].value};
  width: 100%;
  height: 100%;
  border: 0;
`;
