import { useEffect, useState } from "react";
import { observer } from "mobx-react";

import { parseUrl } from "src/func/url";

//component
import { Marker } from "./views/Marker";

//store

import { useURI } from "ms_router";
import { TMapStore } from "src/pages/Search/stores/TMapStore";
import { KMapStore, Map } from "ms-ui";
import { URI_FORM } from "src/interface/pages/Search/uri";
import { MSHUTTLE_DATA } from "src/constants/addr";
import { SDKLoadFailed } from "src/components/NoMap";

interface Props {
  type: "start" | "end";
  confirm: boolean;
  tmapStore: TMapStore;
  mapStore: KMapStore;
}

const SMap = ({ type, confirm, mapStore, tmapStore }: Props) => {
  const { getQuery } = useURI();
  const q = getQuery<URI_FORM>();

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <>
        <Map
          appKey={MSHUTTLE_DATA.KAKAO_JS}
          mapStore={mapStore}
          options={{
            center: {
              lat: q[`${type}`]?.lat ?? 37.6025229689525,
              lng: q[`${type}`]?.lng ?? 127.01100737843952,
            },
            level: 3,
          }}
          callback={(ename: string, obj: any) => {
            if (ename === "dragend") {
              console.log("obj.center: ", obj.center);
              tmapStore.setSpot(obj.center.lat, obj.center.lng);
            }

            if (ename === "zoom_changed") {
              tmapStore.setSpot(obj.center.lat, obj.center.lng);
            }
          }}
          width="100%"
          height="100%"
        >
          {!mapStore.hasMapSdk() && <SDKLoadFailed />}
          {/* 맵 바깥에 따로 마커가 있는거임, center 위치만 알려주는 용도 */}
          <Marker type={type} confirm={confirm} />
        </Map>
      </>
    </div>
  );
};

export default observer(SMap);
