import { observer } from "mobx-react";

import { BackHistory } from "src/func/url";

import Form from "./views/Form";
import styled from "styled-components";
import { Back } from "ms-ui";
import { useURI } from "ms_router";
import { TMapStore } from "src/pages/Search/stores/TMapStore";

interface IProps {
  tmapStore: TMapStore;
}

const Top = ({ tmapStore }: IProps) => {
  const { navigate } = useURI();

  return (
    <TopFixed>
      <MapBackBtn
        onClick={() => {
          BackHistory(navigate, "/");
        }}
      >
        <Back />
      </MapBackBtn>
      <Form tmapStore={tmapStore} />
    </TopFixed>
  );
};
export default observer(Top);

const TopFixed = styled.div`
  position: fixed;
  z-index: 1030;

  width: 100%;
  max-width: 420px;

  padding: 16px;
  padding-top: 24px;

  display: flex;
  gap: 16px;
`;

const MapBackBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--colors-gray-000);
  box-shadow: var(--box-shadow-600);
  border: none;
  width: 48px;
  height: 40px;
  border-radius: 20px;
  margin-top: 5px;

  cursor: pointer;
`;
