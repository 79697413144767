import React, { useEffect, useRef } from "react";

import styled from "styled-components";
import { Body1, Body2, pointerEvt } from "ms-ui";
import { preventScrollEvent } from "src/func/scroll";
import { observer } from "mobx-react";
import { ISpot } from "src/interface/pages/Search/spot";

interface Props {
  spot: ISpot;
  type: "start" | "end";
  onClick?: Function;
  // gtm?: string;
}

const SpotInput = (props: Props) => {
  const { spot, onClick } = props;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.addEventListener("touchmove", preventScrollEvent, {
      passive: false,
    });
    return () => {
      ref.current?.removeEventListener("touchmove", preventScrollEvent);
    };
  }, []);

  return (
    <StInput ref={ref} onClick={() => onClick && onClick()}>
      <StInputIcon type={props.type} />
      <StInputTitle>{spot.title || ""}</StInputTitle>
      <StInputUtil>변경</StInputUtil>
    </StInput>
  );
};

export default observer(SpotInput);

const StInput = styled.div`
  display: flex;
  align-items: center;

  padding: var(--spacing-12) var(--spacing-16);
  flex: 1;
  height: 50px !important;
  background-color: var(--colors-gray-000);

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:first-child {
    border-top-left-radius: var(--border-radius-5);
    border-top-right-radius: var(--border-radius-5);
  }
  &:last-child {
    border-bottom-left-radius: var(--border-radius-5);
    border-bottom-right-radius: var(--border-radius-5);
  }

  ${(p) => pointerEvt(p["data-id"])};
`;

const StInputIcon = styled.div<{ type: "start" | "end" | "time" }>`
  width: 10px;
  height: 10px;
  border-radius: 5px;

  ${(p) =>
    p.type === "start" ? `background-color: var(--colors-green-500);` : ""};
  ${(p) =>
    p.type === "end" ? `background-color: var(--colors-red-500);` : ""};
  ${(p) =>
    p.type === "time" ? `background-color: var(--colors-subblue-500);` : ""};
`;

const StInputTitle = styled.div`
  ${Body1}

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  width: 100%;
  flex: 1;
  padding-left: var(--spacing-16);
`;

const StInputUtil = styled.div`
  ${Body2}
  color: var(--colors-gray-200);
  /* padding-right: var(--spacing-16); */
`;
