import { ITime } from "ms_data";
import { channelIO } from "src/func/tracking/channel";
import { pushDataLayer } from "src/func/tracking/gtm";
import { ISearchList } from "src/interface/pages/Search/api";
import { ISpot } from "src/interface/pages/Search/spot";

export const SearchEvent = ({
  start,
  end,
  time,
  timeInfo,
  list,
  recomYn,
}: {
  start?: ISpot;
  end?: ISpot;
  time?: string[];
  timeInfo: ITime[];
  list?: ISearchList[];
  recomYn?: string;
}) => {
  console.table({ list, start, end, time, timeInfo });

  if (!list) {
    return;
  }

  if (!start || !end || !timeInfo) {
    return;
  }

  const data: any = {};
  data.start = start.address;
  data.end = end.address;

  if (time) {
    data.timeAlias = timeInfo
      .filter((item) => time.indexOf(item.timeId) !== -1)
      .map(({ time }) => time)
      .reduce((acc, str, i) => {
        if (i !== 0) {
          str = "&" + str;
        }
        acc += str;
        return acc;
      }, "/");
  }

  if (!time) {
    data.timeAlias = timeInfo
      .map(({ time }) => time)
      .reduce((acc, str, i) => {
        if (i !== 0) {
          str = "&" + str;
        }
        acc += str;
        return acc;
      }, "/");
  }

  // pixelEvent("Search", "Search", {
  //   content_category: "/searchResult",
  //   content_ids: "?",
  //   contents: "?",
  //   currency: "?",
  //   search_string: `${data.start}&${data.end}${data.timeAlias}`,
  //   value: "?",
  // });

  const runningCnt = list.filter((x) => x.rtCd === "b").length;
  const scheduledCnt = list.filter((x) => x.rtCd === "c").length;

  let type = "00";
  if (runningCnt > 0 && scheduledCnt > 0) {
    type = "11";
  } else if (runningCnt > 0 && scheduledCnt === 0) {
    type = "10";
  } else if (runningCnt === 0 && scheduledCnt > 0) {
    type = "01";
  }

  let selectedTimeList: string[] = [];
  if (time) {
    time.forEach((t) => {
      selectedTimeList.push(t);
    });
  }

  pushDataLayer(`search`, {
    runn_cnt: runningCnt,
    schd_cnt: scheduledCnt,
    recom_yn: recomYn === "1" ? "1" : "0",
    start_addr: data.start,
    end_addr: data.end,
    time_list: selectedTimeList,
  });

  // const label = `start-${data.start}&end-${data.end}/type-${type}${data.timeAlias}`;

  // hackleEvent("view_search_result", label, {
  //   runn_cnt: runningCnt,
  //   schd_cnt: scheduledCnt,
  //   recom_yn: recomYn === "1" ? "1" : "0",
  //   start_addr: data.start,
  //   end_addr: data.end,
  //   time_list: timeInfo
  //     .filter((item) => time.indexOf(item.timeId) !== -1)
  //     .map(({ time }) => time),
  // });

  channelIO.event("Search", {
    category: "Search",
    label: `start-${data.start}&end-${data.end}${data.timeAlias}`,
  });

  // kakaoad.search({
  //   keyword: `start-${data.start}&end-${data.end}${data.timeAlias}`,
  //   tag: "",
  // });

  return { runnCnt: runningCnt, schdCnt: scheduledCnt };
};

//search_input에서 최근검색어 선택
export const clickSearchLately = (recentData: ISpot, type: "start" | "end") => {
  const properties = {
    keyword: recentData.title,
    addr_kor: recentData.address,
    addr_zip_cd: recentData.zipCode,
    addr_emd_cd: recentData.legalDongCode,
  };
  const eventKey =
    type === "start" ? `click_search_latelyStart` : `click_search_latelyEnd`;
  const eventLabel =
    type === `start`
      ? "검색_출발지 최근검색 선택"
      : "검색_도착지 최근검색 선택";

  pushDataLayer(eventKey, properties);
  // hackleEvent(eventKey, eventLabel, properties);
};

//search_input에서 검색결과 선택
export const clickSearchResult = (searchData: ISpot, type: "start" | "end") => {
  const properties = {
    keyword: searchData.title,
    addr_kor: searchData.address,
    addr_zip_cd: searchData.zipCode,
    addr_emd_cd: searchData.legalDongCode,
  };
  const eventKey =
    type === "start" ? `click_search_resultStart` : `click_search_resultEnd`;
  const eventLabel =
    type === `start`
      ? "검색_출발지 검색결과 선택"
      : "검색_도착지 검색결과 선택";

  pushDataLayer(eventKey, properties);
};

//search_map에서 검색결과 선택
export const clickSearchSelect = (
  searchData: ISpot | undefined,
  selectedData: ISpot,
  zoom_level: number,
  type: "start" | "end"
) => {
  const properties = {
    //검색
    keyword: searchData?.title,
    addr_kor: searchData?.address,
    addr_zip_cd: searchData?.zipCode,
    addr_emd_cd: searchData?.legalDongCode,

    //선택
    select_addr_kor: selectedData.address,
    select_addr_emd_cd: selectedData.legalDongCode,
    select_addr_zip_cd: selectedData.zipCode,
    zoom_lv: zoom_level,
  };

  const eventKey =
    type === "start" ? `click_search_selectStart` : `click_search_selectEnd`;
  const eventLabel =
    type === `start` ? "검색_출발지로 선택 버튼" : "검색_도착지로 선택 버튼";

  pushDataLayer(eventKey, properties);
  // hackleEvent(eventKey, eventLabel, properties);
};
