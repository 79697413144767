import { observer } from "mobx-react";
import { useLocation } from "react-router";
import { parseUrl } from "src/func/url";

import { KMapStore, Marker } from "ms-ui";
import { URI_FORM } from "src/interface/pages/Search/uri";
import { ms_file } from "src/constants/addr";

interface Props {
  mapStore: KMapStore;
}

const MarkerView = ({ mapStore }: Props) => {
  const location = useLocation();
  const { start, end } = parseUrl(location.search) as URI_FORM;

  return (
    <>
      {start && (
        <Marker
          map={mapStore.map!}
          lat={start.lat}
          lng={start.lng}
          zIndex={1}
          img={{
            src: `${ms_file}/image/marker/boarding/start_marker_kor.svg`,
            size: {
              width: 60,
              height: 78,
            },
          }}
          callback={() => {}}
        />
      )}

      {end && (
        <Marker
          map={mapStore.map!}
          lat={end.lat}
          lng={end.lng}
          zIndex={1}
          img={{
            src: `${ms_file}/image/marker/getoff/end_marker_kor.svg`,
            size: {
              width: 60,
              height: 78,
            },
          }}
          callback={() => {}}
        />
      )}
    </>
  );
};
export default observer(MarkerView);
