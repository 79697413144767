import styled from "styled-components";

import { Body1, pointerEvt } from "ms-ui";
import { TMAP_POI } from "src/interface/pages/Search/tmap";

interface Props {
  onClick: (e: any) => void;
  // gid?: string;
  dataId?: string;
  dataIdx?: number | string;
  spot: TMAP_POI;
}

const ResultSearchItem = ({ onClick, spot, dataId, dataIdx }: Props) => {
  return (
    <StResultSearchItem
      onClick={(e: any) => onClick(spot)}
      data-id={dataId && dataId}
      data-idx={dataIdx && dataIdx}
      // id={gid && gid}
    >
      <StResultSearchText>{spot.name}</StResultSearchText>
    </StResultSearchItem>
  );
};

export default ResultSearchItem;

const StResultSearchItem = styled.div`
  display: flex;
  flex-direction: row;
  background-color: var(--colors-gray-000);
  border-bottom: solid 1px var(--colors-gray-090);
  padding: var(--spacing-16);
  width: 100%;
  cursor: pointer;

  ${(p) => pointerEvt(p["data-id"])}
`;

const StResultSearchText = styled.button`
  ${Body1}
  text-align: left;
  color: var(--colors-gray-500);

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
