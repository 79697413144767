import React, { useState } from "react";

import { observer } from "mobx-react";
import { ToolTipWrapper } from "src/components/TooltipWrapper";
import { CloseToolTip } from "src/components/CloseTooltip";

const TEXT_TEMPLATE = {
  start: "탑승지",
  end: "하차지",
  both: "탑승/하차지",
} as const;

interface Props {
  addable?: "start" | "end" | "both";
  active: boolean;
}

export const BusStopAddTooltip = ({ addable, active }: Props) => {
  const [view, setView] = useState<boolean>(true);

  const close = () => {
    setView(false);
  };

  return (
    <>
      {view && addable !== undefined && (
        <>
          <ToolTipWrapper active={active}>
            <CloseToolTip direction={`up`} floating={true} close={close}>
              <div>{TEXT_TEMPLATE[addable]} 추가가 가능한 출근길이에요!</div>
              <div>출근길을 살펴보시고, 탑승 신청 시 요청하세요.</div>
            </CloseToolTip>
          </ToolTipWrapper>
        </>
      )}
    </>
  );
};

export default observer(BusStopAddTooltip);
