import { useEffect, useState } from "react";
import RecentSearchItem from "./views/Item";

import styled from "styled-components";
import RecentNone from "./views/RecentNone";
import { getLS, setLS } from "src/func/storage";
import { Caption1, ModalConfirm } from "ms-ui";
import { ISearchData } from "src/interface/pages/Search/spot";

interface Props {
  type: "start" | "end";
  clickRecent: (storage: ISearchData) => void;
}

const RecentSearchList = (props: Props) => {
  const { type, clickRecent } = props;
  const [confirm, setConfirm] = useState({ open: false, msg: "" });
  const [searchHistory, setSearchHistory] = useState([]);

  /**
   * 검색 기록을 가져옴
   */
  const getSearchHistory = (type: "start" | "end") => {
    const historyData = getLS(`${type}SearchHistory`);
    let historyList = [];
    if (historyData) {
      try {
        historyList = JSON.parse(historyData);
      } catch (error) {
        historyList = [];
      }
    }

    if (historyList.length > 0) {
      setSearchHistory(historyList);
    }
  };

  useEffect(() => {
    getSearchHistory(type);
  }, [type]);

  /**
   * 모든 검색 기록을 지운다.
   */
  const deleteAllHistory = (type: "start" | "end") => {
    try {
      setLS(`${type}SearchHistory`, []);
      setSearchHistory([]);
    } catch (error) {}
  };

  const id =
    type === "start"
      ? "search_latelyStart"
      : type === "end"
      ? "search_latelyEnd"
      : undefined;

  return (
    <>
      <ModalConfirm
        open={confirm.open}
        msg={confirm.msg}
        onClick={() => {
          deleteAllHistory(type);
          setConfirm({ open: false, msg: "" });
        }}
        onCancel={() => setConfirm({ open: false, msg: "" })}
        cancelText="아니오"
        confirmText="예"
      />

      {searchHistory.length > 0 && (
        <StTitle>
          <div>최근 검색 결과</div>
          <StRecentSearchRemove
            onClick={() =>
              setConfirm({ open: true, msg: "정말 삭제하시겠습니까?" })
            }
          >
            전체삭제
          </StRecentSearchRemove>
        </StTitle>
      )}

      <StRecentResultList>
        {searchHistory.length === 0 ? (
          <RecentNone type={type} />
        ) : (
          <>
            {searchHistory.map((item: ISearchData, i: number) => (
              <RecentSearchItem
                onClick={() => clickRecent(item)}
                {...item}
                key={i}
                dataId={id && id}
                dataIdx={id && i}
                // id={id && id + "_" + i}
              />
            ))}
          </>
        )}
      </StRecentResultList>
    </>
  );
};

export default RecentSearchList;

const StTitle = styled.div`
  ${Caption1}
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background-color: var(--colors-gray-000);
  border-bottom: solid 1px var(--colors-gray-090);
  color: var(--colors-gray-400);
`;

const StRecentSearchRemove = styled.button`
  ${Caption1}
  color: var(--colors-gray-300);
  cursor: pointer;
`;

const StRecentResultList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 16px;
  color: var(--colors-gray-500);
`;
