// const { axios } = require('axios');

export default function LogWorker() {
  const cstHeaders = new Headers();
  cstHeaders.append("Content-Type", "application/json; charset=UTF-8");
  cstHeaders.append("Accept", "application/json");

  /* eslint-disable-next-line no-restricted-globals */
  const sctx: Worker = self as any;
  onmessage = function (e: any) {
    if (e.data.on === true) {
      const { data, url, customHeader } = e.data;

      if (customHeader) {
        Object.keys(customHeader).forEach((key) => {
          if (!cstHeaders.get("key")) {
            cstHeaders.append(`${key}`, customHeader[`${key}`]);
          }
        });
      }

      fetch(url, {
        headers: cstHeaders,

        method: "post",
        body: JSON.stringify(data),
      })
        .then((response) => {
          console.log("response: ", response);
          if (response.status === 200) {
            // return response.json();
          } else {
            // return null;
          }
          return;
        })
        .then((res) => {
          sctx.postMessage({
            res,
          });
        });
    }
  };
}
