import { pushDataLayer } from "src/func/tracking/gtm";

/**
 * 추천을 해줬음에도 불구하고 알림받기 페이지로 이동했는지, 없어서 이동했는지 체크
 * @param rt_yn 추천 여부 0 | 1
 */
export const goMakeEvt = (rt_yn: number) => {
  const evtName = "click_search_make";
  const label = "";
  pushDataLayer(evtName, { rt_yn });
  // hackleEvent(evtName, label, { rt_yn });
};
