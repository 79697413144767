import { observer } from "mobx-react";
import SwiperStore from "../../../SearchResultList/SwiperStore";

import { ClassCustomOverlay, KMapStore } from "ms-ui";
import { SearchStore } from "src/pages/Search/stores/SearchStore";
import { CstCustomOverlay } from "./CstCustomOverlay";

interface Props {
  mapStore: KMapStore;
  searchStore: SearchStore;
  swiperStore: SwiperStore;
  overlayVisible: boolean;
}

/**
 * CustomOverlay 위치 잡는 방식 => 지도상에서의 위치 + 그 위치를 기준으로 CSS로 이동시킨 픽셀
 *
 * 지도상에서의 위치는 지도 좌표계에 고정되어 있지만, css로 이동시킨 픽셀은 스크린상으로 고정된 수치라 확대된 레벨에서는 비교적 원 위치와 많이 가까이 있는 것 처럼 보이지만
 * 축소된 레벨에서는 1px이 지도상에서 수km일 것이므로 어긋나는 것 처럼 보임.
 */
const CustomOverlaysView = ({
  mapStore,
  searchStore,
  swiperStore,
  overlayVisible,
}: Props) => {
  const { data } = searchStore;
  const { activeIndex } = swiperStore;

  return (
    <>
      {data && activeIndex !== undefined && activeIndex < data.length && (
        <>
          <ClassCustomOverlay
            map={mapStore.map!}
            options={{
              position: {
                lat: data[activeIndex]["start"].lat,
                lng: data[activeIndex]["start"].lng,
              },
              zIndex: 1,
              yAnchor: 1,
            }}
            visible={overlayVisible}
          >
            <CstCustomOverlay
              rtId={data[activeIndex].rtId}
              rtCd={data[activeIndex].rtCd}
              lat={data[activeIndex]["start"].lat}
              lng={data[activeIndex]["start"].lng}
              markerType="start"
            />
          </ClassCustomOverlay>

          <ClassCustomOverlay
            map={mapStore.map!}
            options={{
              position: {
                lat: data[activeIndex]["end"].lat,
                lng: data[activeIndex]["end"].lng,
              },
              zIndex: 1,
              yAnchor: 1,
            }}
            visible={overlayVisible}
          >
            <CstCustomOverlay
              rtId={data[activeIndex].rtId}
              rtCd={data[activeIndex].rtCd}
              lat={data[activeIndex]["end"].lat}
              lng={data[activeIndex]["end"].lng}
              markerType="end"
            />
          </ClassCustomOverlay>
        </>
      )}
    </>
  );
};

export default observer(CustomOverlaysView);
