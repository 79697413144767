import React, { useEffect, useRef } from "react";
import { preventScrollEvent } from "src/func/scroll";
import styled from "styled-components";

import { observer } from "mobx-react";

import { Body1, pointerEvt } from "ms-ui";
import { TextShort } from "src/components/style";

/** */
interface Props {
  type: "start" | "end" | "time";
  title: string | JSX.Element;
  onClick?: Function;
  dataId?: string;
}

const Input = (props: Props) => {
  const { type, title, onClick, dataId } = props;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.addEventListener("touchmove", preventScrollEvent, {
      passive: false,
    });
    return () => {
      ref.current?.removeEventListener("touchmove", preventScrollEvent);
    };
  }, []);

  return (
    <StInput
      ref={ref}
      onClick={() => onClick && onClick()}
      data-id={dataId}
      // id={gtm}
    >
      <StInputIcon type={type} />
      <StInputTitle>{title || ""}</StInputTitle>
    </StInput>
  );
};

export default observer(Input);

const StInput = styled.div`
  display: flex;
  align-items: center;

  padding: var(--spacing-12) var(--spacing-16);
  flex: 1;
  height: 50px !important;
  background-color: var(--colors-gray-000);

  ${TextShort}

  &:first-child {
    border-top-left-radius: var(--border-radius-5);
    border-bottom-left-radius: var(--border-radius-5);
  }
  &:last-child {
    border-top-right-radius: var(--border-radius-5);
    border-bottom-right-radius: var(--border-radius-5);
  }

  ${(p) => pointerEvt(p["data-id"])};
`;

const StInputIcon = styled.div<{ type: "start" | "end" | "time" }>`
  width: 10px;
  height: 10px;
  border-radius: 5px;

  ${(p) =>
    p.type === "start" ? `background-color: var(--colors-green-500);` : ""};
  ${(p) =>
    p.type === "end" ? `background-color: var(--colors-red-500);` : ""};
  ${(p) =>
    p.type === "time" ? `background-color: var(--colors-subblue-500);` : ""};
`;

const StInputTitle = styled.div`
  ${Body1}
  ${TextShort}
  width: 100%;
  flex: 1;
  padding-left: var(--spacing-16);
`;
