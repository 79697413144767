import ResultForm from "./views/ResultForm";
import FakeForm from "./views/FakeForm";
import SwiperStore from "../SearchResultList/SwiperStore";
import { KMapStore } from "ms-ui";
import { SearchStore } from "src/pages/Search/stores/SearchStore";
import { useURI } from "ms_router";
import { URI_FORM } from "src/interface/pages/Search/uri";

interface Props {
  mapStore: KMapStore;
  searchStore: SearchStore;
  swiperStore: SwiperStore;
  formType: "RESULT" | "FAKE";
  setFormType: (type: "RESULT" | "FAKE") => void;
}

const SearchZone = ({
  mapStore,
  searchStore,
  swiperStore,
  formType,
  setFormType,
}: Props) => {
  const { getQuery } = useURI();

  /**
   * formType change
   */
  const changeFormType = () => {
    setFormType(formType === "RESULT" ? "FAKE" : "RESULT");
  };

  /**
   * bound change
   * @param km
   */
  const setBound = (km: 1 | 2) => {
    swiperStore.slideTo(0);
    searchStore.setBound(km);
  };

  /**
   * viewType을 바꾸고, 바운드도 바꾼다.
   */
  const setViewType = (vType: "start" | "end") => {
    searchStore.setViewType(vType);

    const { data } = searchStore;
    const { activeIndex } = swiperStore;
    if (!data) return;
    if (activeIndex >= data.length) return;

    const form = getQuery<URI_FORM>();

    let point1: any, point2: any;
    point1 = [+data[activeIndex][vType].lat, +data[activeIndex][vType].lng];

    if (form[vType]?.lat && form[vType]?.lng) {
      point2 = [+form[vType]!.lat, +form[vType]!.lng];
    }

    if (!point1 || !point2) return;

    mapStore.setBoundsByPoints(
      [
        [point1[0], point1[1]],
        [point2[0], point2[1]],
      ],
      [200, 32, 210, 32],
      false
    );
    // mapStore.setBoundsByPoints([point1, point2], [250, 32, 210, 32]);
  };

  return (
    <>
      <ResultForm formType={formType} changeFormType={changeFormType} />
      <FakeForm
        formType={formType}
        searchStore={searchStore}
        swiperStore={swiperStore}
        setBound={setBound}
        changeFormType={changeFormType}
        setViewType={setViewType}
      />
    </>
  );
};
export default SearchZone;
