import googlePolyline from "google-polyline";

export type Coordinate = { lat: number | string; lng: number | string };

/**
 * 구글라이브러리로 암호화된 경로 텍스트 복호화
 * @param text 암호화된 텍스트
 */
export const decodePolyLine = (text: string) => {
  return googlePolyline.decode(decodeURIComponent(text));
};
