import { useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import { useURI } from "ms_router";

//component
import SearchTime from "./views/searchTime/NewTime";
import SearchInput from "./views/searchInput";
import SearchMap from "./views/searchMap";
import SearchResult from "./views/searchResult";

//store
import { SearchStore } from "./stores/SearchStore";
import { URI_FORM } from "src/interface/pages/Search/uri";

const Search = () => {
  const { navigate, location, getQuery } = useURI();

  const [searchStore] = useState(() => new SearchStore(getQuery<URI_FORM>()));

  useEffect(() => {
    if (location.pathname === "/search") {
      navigate("/search/start_form", { replace: true });
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path="/start_form" Component={() => <SearchInput />} />
        <Route path="/start_map" Component={() => <SearchMap />} />
        <Route path="/end_form" Component={() => <SearchInput />} />
        <Route path="/end_map" Component={() => <SearchMap />} />
        <Route path="/select_time" Component={() => <SearchTime />} />
        <Route
          path="/select_path_map"
          Component={() => <SearchResult searchStore={searchStore} />}
        />
      </Routes>
    </>
  );
};

export default Search;
