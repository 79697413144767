import { MSHUTTLE_ASSETS } from "src/constants/addr";
import styled from "styled-components";

interface IProps {
  rtId: string;
  rtCd: string;
  markerType: "start" | "end";
  lat: number;
  lng: number;
}

export const CstCustomOverlay = ({
  rtId,
  rtCd,
  markerType,
  lat,
  lng,
}: IProps) => {
  return (
    <StBusStopMarker
      className={`BusStopMarker focus ${
        rtCd === "b" ? "running" : "scheduled"
      } s${rtId} ${markerType} bs_marker${rtId}`}
      data-coords={`(${lat}, ${lng})`}
      data-rtid={`${rtId}`}
      data-type={markerType === "start" ? "home" : "work"}
    >
      <StBusStopMarkerInner className={`BusStopMarker__inner `}>
        <img
          src={`${MSHUTTLE_ASSETS}/images/common/marker_bus_${
            rtCd === "b" ? "run" : "sche"
          }.svg`}
          className={`BusStopMarker__img ${
            rtCd === "b" ? "running" : "scheduled"
          }`}
        />
      </StBusStopMarkerInner>
    </StBusStopMarker>
  );
};

const StBusStopMarker = styled.div`
  width: 52px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StBusStopMarkerInner = styled.div``;
