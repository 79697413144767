import React, { useEffect } from "react";

import styled from "styled-components";

import { EvtStore } from "../../../../../stores/EvtStore";
import { Body2, Button, Subtitle2 } from "ms-ui";
import { searchresultEvtId } from "src/constants/evt/search";

interface Props {
  goMake: () => void;
  evtStore: EvtStore;
}

const NoResultCard = ({ goMake, evtStore }: Props) => {
  useEffect(() => {
    evtStore.setMakeView(true);
  }, []);

  return (
    <EtcCardLayer>
      <EtcCard
        // data-id={"card_make_noresult"}
        // id={searchresultEvtId.card_make_noresult.key}
        onClick={goMake}
      >
        <EtcCardContent>
          현재 탑승할 수 있는 출근길이 없어요. <br />
          <EtcCardContentRed>알림받기</EtcCardContentRed>
          를 하면 검색하신 정보로
          <br />
          <EtcCardContentNoti>
            맞춤 출근길을 만들어 드려요!
            <EtcCardIcon>📢</EtcCardIcon>
          </EtcCardContentNoti>
        </EtcCardContent>

        <Button
          color={`primary`}
          size="rg"
          radius={"5"}
          // id={searchresultEvtId.card_make_noresult.key}
          width="100%"
        >
          내 출근길 알림받기
        </Button>
      </EtcCard>
    </EtcCardLayer>
  );
};

export default NoResultCard;

const EtcCardLayer = styled.div`
  position: static;
  bottom: var(--spacing-24);
  z-index: 1050;
  width: 100%;

  & > * :not(button) {
    pointer-events: none;
  }
`;

const EtcCard = styled.div`
  margin: 0 auto;
  background-color: var(--colors-gray-000);
  box-shadow: 0 0 0 2px var(--colors-mainblue-500) inset;
  border-radius: var(--border-radius-5);
  padding: var(--spacing-16);
`;

const EtcCardContent = styled.div`
  ${Body2}
  color: var(--colors-gray-500);
  text-align: center;
  margin-bottom: var(--spacing-16);
`;

const EtcCardContentRed = styled.span`
  ${Subtitle2}
  color: var(--colors-red-500);
`;

const EtcCardContentNoti = styled.span`
  ${Subtitle2}
  color: var(--colors-gray-500);
`;

const EtcCardIcon = styled.span`
  transform: matrix(-1, 0, 0, 1, 0, 0);
`;
