import { useEffect, useState } from "react";
import { getEndOfToday } from "src/func/moment";

import styled from "styled-components";
import { CloseToolTip } from "src/components/CloseTooltip";
import { cookie } from "ms-ui";
import { observer } from "mobx-react";

interface Props {
  setDimmed: (dim: boolean) => void;
}

const COOKIE_KEY = "SEARCHMATCHING_TOOTIP_CLOSED";

export const SearchMatchingTooltip = ({ setDimmed }: Props) => {
  const [view, setView] = useState<boolean>(true);

  const setCookie = async (expires: Date) => {
    cookie.set(COOKIE_KEY, "true", {
      expires,
    });
  };

  const close = () => {
    const expires: Date = getEndOfToday();
    setCookie(expires).then(() => {
      setView(false);
      setDimmed(false);
    });
  };

  useEffect(() => {
    const show = cookie.get(COOKIE_KEY);
    console.log("show: ", show);
    let tick: any;

    if (!show) {
      setDimmed(true);
      tick = setTimeout(() => close(), 5000);
    } else {
      setView(false);
    }

    return () => clearTimeout(tick);
  }, []);

  return view ? (
    <>
      <StyledOverlay>
        <OverlayBackground></OverlayBackground>
      </StyledOverlay>

      <StToolTip>
        <CloseToolTip direction={`up`} floating={false} close={close}>
          <div className={`title`}>출근길이 자동 매칭되었습니다.</div>
          <div>
            가장 가까운 탑승/하차지로 출근길이 자동 <br />
            매칭되었습니다. 하단 출근길 카드를 확인해 주세요! <br />
            탑승/하차지 추가 요청도 가능해요.
          </div>
        </CloseToolTip>
      </StToolTip>
    </>
  ) : null;
};

export default observer(SearchMatchingTooltip);

const StToolTip = styled.div`
  position: fixed;
  padding: 0 var(--spacing-16);

  bottom: 200px;
  width: 100%;

  max-width: 420px;
`;

const StyledOverlay = styled.div`
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 0;
`;

const OverlayBackground = styled.div`
  transform: translateY(100);
  @supports (padding-top: constant(safe-area-inset-top)) {
    padding: constant(safe-area-inset-top) constant(safe-area-inset-right)
      constant(safe-area-inset-bottom) constant(safe-area-inset-left);
  }
  @supports (padding-top: env(safe-area-inset-top)) {
    padding: env(safe-area-inset-top) env(safe-area-inset-right)
      env(safe-area-inset-bottom) env(safe-area-inset-left);
  }

  width: 100%;
  max-width: 420px;

  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;

  margin: 0 auto;
  /* transform: translateY(100%); */
`;
