import { Body2, Cancel, Subtitle2, pointerEvt, zindex_tooltip } from "ms-ui";
import styled from "styled-components";

interface styledCloseToolTipProps {
  width?: string;
  id?: string;
  direction?: "up" | "down";
  close: () => void;
  floating?: boolean;
}

export interface ICloseToolTip extends styledCloseToolTipProps {
  children: any;
}

export const CloseToolTip = (props: ICloseToolTip) => {
  return (
    <div style={{ position: `relative` }}>
      <StyledCloseToolTipLayout
        width={props.width}
        $direction={props.direction}
        $floating={props.floating}
      >
        <StyledCloseBtnCtn>
          <StyledCloseBtn
            onClick={(e: any) => props.close()}
            // id={props.id}
          >
            <Cancel color={`var(--colors-gray-000)`} />
          </StyledCloseBtn>
        </StyledCloseBtnCtn>
        <StyledCloseToolTipTxt>{props.children}</StyledCloseToolTipTxt>
      </StyledCloseToolTipLayout>
    </div>
  );
};
// default value
CloseToolTip.defaultProps = {};

const StyledCloseBtnCtn = styled.div`
  position: relative;
  width: 100%;
  height: 28px;
`;

const StyledCloseBtn = styled.div`
  position: absolute;
  padding-top: var(--spacing-4);
  padding-right: var(--spacing-8);
  top: 0;
  right: 0;
  cursor: pointer;

  ${(p) => pointerEvt(p["data-id"])};
`;

const StyledCloseToolTipLayout = styled.div<{
  width: string | undefined;
  $floating: boolean | undefined;
  $direction: string | undefined;
}>`
  width: ${(p) => p.width || "100%"};
  margin-left: auto;
  margin-right: auto;

  text-align: center;
  border-radius: var(--border-radius-10);

  z-index: ${zindex_tooltip};
  will-change: transform;

  /* background-color */
  background-color: var(--colors-mainblue-400);
  /* font color */

  /* floating */
  ${(p) => (p.$floating !== false ? "position: absolute;" : null)}

  /* textarea spot */
  ${(p) =>
    p.$direction === "down"
      ? `top: calc( 100% + var(--spacing-16) + var(--spacing-8));`
      : `bottom: calc( 100% + var(--spacing-16) + var(--spacing-8));`}
  
  &:after {
    content: "";
    position: absolute;
    left: calc(50% - 32px / 2);

    /* arrow spot */
    ${(p) =>
      p.$direction === "down"
        ? `top: calc(-1 * 32px);`
        : `bottom: calc(-1 * 32px);`}

    width: calc(32px - 1px);
    height: calc(32px - 1px);
    border: calc(32px / 2) solid rgba(0, 0, 0, 0);

    /* arrow direction */
    ${(p) =>
      p.$direction === "down"
        ? `border-bottom: calc(32px / 2) solid var(--colors-mainblue-400);`
        : `border-top: calc(32px / 2) solid var(--colors-mainblue-400);`}
  }
`;

const StyledCloseToolTipTxt = styled.div`
  padding: 0 var(--spacing-16) var(--spacing-24);
  color: var(--colors-gray-000);
  ${Body2}

  .title {
    ${Subtitle2}
  }
`;
