export const SearchEmpty = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.2206 28.4058C51.7529 28.4058 53.0023 29.6552 53.0023 31.1876V31.3604L53.0259 31.5333L55.5876 48.8995C55.509 50.369 54.291 51.5241 52.8216 51.5319L5.20986 50.9819H5.18629C3.70898 50.9819 2.49099 49.8268 2.41241 48.3573L4.97412 31.5412L4.9977 31.3604V31.1797C4.9977 29.6474 6.23926 28.398 7.77943 28.398L50.2206 28.4058ZM50.2206 25.9934H7.77943C4.91126 25.9934 2.59314 28.3115 2.59314 31.1797L0 48.208C0 51.0762 2.31811 53.3943 5.18629 53.3943L52.8137 53.9444C55.6819 53.9444 58 51.6262 58 48.7581L55.4069 31.1876C55.4069 28.3194 53.0809 26.0013 50.2206 25.9934Z"
        fill="#BABCBF"
      />
      <path
        d="M54.6341 39.2915L38.7682 51.6592L40.2465 53.5556L56.1124 41.188L54.6341 39.2915Z"
        fill="#BABCBF"
      />
      <path
        d="M5.24553 27.5646L4.39912 29.8153L48.9416 46.5663L49.788 44.3157L5.24553 27.5646Z"
        fill="#BABCBF"
      />
      <path
        d="M17.9968 34.2849L9.15102 51.4738L11.2891 52.5741L20.1349 35.3852L17.9968 34.2849Z"
        fill="#BABCBF"
      />
      <path
        d="M42.713 37.0615C47.5823 37.0615 51.5297 35.7386 51.5297 34.1068C51.5297 32.475 47.5823 31.1522 42.713 31.1522C37.8437 31.1522 33.8963 32.475 33.8963 34.1068C33.8963 35.7386 37.8437 37.0615 42.713 37.0615Z"
        fill="#E9EBEE"
      />
      <path
        d="M42.5181 10.0527C39.2178 10.0527 36.5382 12.7323 36.5382 16.0327C36.5382 19.333 39.2178 22.0126 42.5181 22.0126C45.8185 22.0126 48.4981 19.333 48.4981 16.0327C48.4902 12.7323 45.8185 10.0527 42.5181 10.0527ZM42.5181 19.2623C40.7344 19.2623 39.2885 17.8165 39.2885 16.0327C39.2885 14.2489 40.7344 12.803 42.5181 12.803C44.3019 12.803 45.7478 14.2489 45.7478 16.0327C45.7478 17.8165 44.3019 19.2623 42.5181 19.2623Z"
        fill="#BABCBF"
      />
      <path
        d="M42.5111 5C36.2954 5.00786 31.2505 10.0527 31.2505 16.2684C31.2505 18.6729 32.8064 21.7219 34.7081 24.5743L36.6883 27.3246C38.2049 29.2891 39.8158 31.1672 41.521 32.9588L42.5111 33.9882L43.5012 32.9588C45.2142 31.1672 46.8251 29.2813 48.3417 27.3246L50.3219 24.5743C52.2236 21.7297 53.7795 18.6729 53.7795 16.2684C53.7716 10.0527 48.7346 5.00786 42.5111 5ZM42.5111 29.9885C39.4543 26.6253 34.0008 19.8595 34.0008 16.2684C34.0008 11.5614 37.812 7.7503 42.5189 7.7503C47.2259 7.7503 51.037 11.5614 51.037 16.2684C51.037 19.8595 45.5678 26.6253 42.5111 29.9885Z"
        fill="#BABCBF"
      />
      <path
        d="M42.5103 7.74719C37.8112 7.75505 34.0001 11.5662 34.0001 16.2653C34.0001 19.8564 39.4614 26.6221 42.5181 29.9854C45.5749 26.6143 51.0441 19.8485 51.0441 16.2653C51.0284 11.5662 47.2172 7.75505 42.5103 7.74719ZM42.5103 22.0095C39.2099 22.0095 36.5303 19.3299 36.5303 16.0295C36.5303 12.7292 39.2099 10.0496 42.5103 10.0496C45.8107 10.0496 48.4902 12.7292 48.4902 16.0295C48.4902 19.3299 45.8107 22.0095 42.5103 22.0095Z"
        fill="#E9EBEE"
      />
      <path
        d="M45.7446 16.0294C45.7446 17.8132 44.2988 19.2591 42.515 19.2591C40.7312 19.2591 39.2775 17.8211 39.2775 16.0294C39.2775 14.2457 40.7312 12.7998 42.515 12.7998C44.2988 12.7998 45.7446 14.2457 45.7446 16.0294Z"
        fill="white"
      />
    </svg>
  );
};
