import * as React from "react";

import styled from "styled-components";
import { Body1, Body2 } from "ms-ui";
import { SearchEmpty } from "src/pages/Search/views/SearchEmpty";

interface Props {
  type: string;
}

const RecentNone: React.FC<Props> = (props: Props) => {
  const { type } = props;

  return (
    <StRecentNone>
      <SearchEmpty />
      <StRecentTitle>최근 검색한 내역이 없습니다.</StRecentTitle>

      <StRecentContent>
        {type === "start" ? "출발지" : "도착지"}와 제일 가까운 장소로
        검색해주세요.
        <br />
        모두의셔틀이 최대한 가까이 모시러 갈게요!
      </StRecentContent>
    </StRecentNone>
  );
};

export default RecentNone;

const StRecentNone = styled.div`
  padding-top: var(--spacing-32);
  text-align: center;
`;

const StRecentTitle = styled.div`
  margin-top: var(--spacing-8);
  margin-bottom: var(--spacing-4);
  ${Body1}
  color: var(--colors-gray-200);
`;

const StRecentContent = styled.div`
  ${Body2}
  color: var(--colors-gray-200);
`;
