import styled from "styled-components";
import SearchKeyword from "src/pages/Search/img/searchkeyword.svg";
import { Body1, CenterFlex, pointerEvt } from "ms-ui";
import { ISearchData, ISpot } from "src/interface/pages/Search/spot";

interface Props extends Partial<ISpot> {
  onClick: (data: ISearchData) => void;
  id?: string;
  dataId?: string;
  dataIdx?: string | number;
}

const RecentSearchItem = ({
  title,
  lat,
  lng,
  address,
  // type,
  onClick,
  id,
  dataId,
  dataIdx,
}: // pageToMove
Props) => {
  return (
    <StRecentSearch
      onClick={() =>
        onClick({ lat: lat!, lng: lng!, title: title!, address: address! })
      }
      data-id={dataId && dataId}
      data-idx={dataIdx && dataIdx}
      // id={id && id}
    >
      <StRecentSearchBorder>
        <StRecentSearchItem src={SearchKeyword} />
        <StRecentSearchText>{title}</StRecentSearchText>
      </StRecentSearchBorder>
    </StRecentSearch>
  );
};

export default RecentSearchItem;

const StRecentSearch = styled.div`
  cursor: pointer;
  background-color: var(--colors-gray-000);
  padding: 16px;
  width: 100%;
  border-bottom: solid 1px var(--colors-gray-090);
  ${(p) => pointerEvt(p["data-id"])}
`;

const StRecentSearchBorder = styled.div`
  width: 100%;
  ${CenterFlex}
`;

const StRecentSearchItem = styled.img`
  margin-right: var(--spacing-16);
`;

const StRecentSearchText = styled.div`
  ${Body1}
  width: 100%;
  text-align: left;
  color: var(--colors-gray-500);

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
