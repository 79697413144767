import { parseUrl, stringifyUrl } from "src/func/url";
import { ISpot } from "src/interface/pages/Search/spot";
import { TMAP_ADDRESS } from "src/interface/pages/Search/tmap";

/**
 * 조회된 주소 데이터를 ISpot 형식으로 변경
 * @param lat
 * @param lon
 * @param addrInfo
 * @returns
 */
export const resToSpot = (
  lat: number,
  lon: number,
  addrInfo: TMAP_ADDRESS
): ISpot => {
  const { fullAddress, city_do, gu_gun } = addrInfo;

  /**
   * fullAddress(행정동 주소,법정동 주소)로 오는걸 구분자(콤마)로 잘라서 법정동 주소를 얻어서 씀
   */
  let fullAddressArr = fullAddress.split(",");
  let address = fullAddressArr[fullAddressArr.length - 1];
  let title = address.replace(city_do, "").trim();

  return {
    title,
    address,
    lat,
    lng: lon,
  };
};

/**
 * 바뀐 spot에 맞게 uri의 쿼리스트링을 바꿔준다.
 * @param spot
 */
export const changeQueryString = (navigate: any, changedSpot: ISpot) => {
  const type = window.location.pathname.includes("start_map") ? "start" : "end";
  const oldSearch = parseUrl(window.location.search);
  let newSearch = {
    ...oldSearch,
    [`${type}`]: changedSpot,
  };
  window.history.replaceState(null, "", `${stringifyUrl(newSearch)}`);
};
