import React from "react";
import styled from "styled-components";
import { SearchEmpty } from "../../../SearchEmpty";
import { Body1, Body2 } from "ms-ui";

const ResultNone = () => {
  return (
    <StResultNone>
      <SearchEmpty />
      <StResultTitle>
        검색한 결과를 찾을 수 없습니다.
        <br />
        다른 검색어로 검색을 해보시겠어요?
      </StResultTitle>
      <StResultContent>
        원하는 검색 결과가 없는 경우, <br />
        근처 지하철 역으로 검색해보세요!
      </StResultContent>
    </StResultNone>
  );
};

export default ResultNone;

const StResultNone = styled.div`
  padding-top: var(--spacing-32);
  text-align: center;
`;

const StResultTitle = styled.div`
  margin-top: var(--spacing-8);
  margin-bottom: var(--spacing-4);
  ${Body1}
  color: var(--colors-gray-200);
`;

const StResultContent = styled.div`
  ${Body2}
  color: var(--colors-gray-200);
`;
