import { parseUrl } from "src/func/url";
import { getDistance } from "ms-ui";
import { ISt } from "ms_data";
import { API_DATA } from "src/interface/pages/Search/api";
import { URI_FORM } from "src/interface/pages/Search/uri";

/**
 * 가까운 순으로 경로 정렬
 * rtCd: 운행중 or 운행예정
 */
export const sortRoute = (results: API_DATA[], rtCd: string) => {
  const filteredResults = results.filter((a: API_DATA) => a.rtCd === rtCd);
  const qs = parseUrl(window.location.search) as URI_FORM;

  const sortedResult = filteredResults.sort((a: API_DATA, b: API_DATA) => {
    const distance_a = getDistance(
      { lat: Number(qs.start!.lat), lng: Number(qs.start!.lng) },
      { lat: a.start.lat, lng: a.start.lng }
    );
    const distance_b = getDistance(
      { lat: Number(qs.start!.lat), lng: Number(qs.start!.lng) },
      { lat: b.start.lat, lng: b.start.lng }
    );

    //더 큰걸 뒤로.
    if (distance_a > distance_b) {
      return 1;
    } else {
      return -1;
    }
  });

  return sortedResult;
};

/**
 * 정류장 추가가 가능한 경로를 구한다.
 */
export const addableRoute = (sortedResult: API_DATA[]) => {
  const qs = parseUrl(window.location.search) as URI_FORM;
  //addable
  const startKeyword = {
    lat: Number(qs.start!.lat),
    lng: Number(qs.start!.lng),
  };
  const endKeyword = { lat: Number(qs.end!.lat), lng: Number(qs.end!.lng) };

  const addableRoute = sortedResult.map((item: API_DATA) => {
    let addable: "start" | "end" | "both" | "" = "";

    const { start, startPt, end, endPt } = item;
    const start_nearest = { lat: startPt.pt[1], lng: startPt.pt[0] };
    const start_busStop = { lat: start.lat, lng: start.lng };
    // const start_busStop = { lat: start_st_lat, lng: start_st_lng };
    const startAddable = checkBusStopAddable(
      startKeyword,
      start_nearest,
      start_busStop
    );

    const end_nearest = { lat: endPt.pt[1], lng: endPt.pt[0] };
    const end_busStop = { lat: end.lat, lng: end.lng };
    // const end_busStop = { lat: end_st_lat, lng: end_st_lng };
    const endAddable = checkBusStopAddable(
      endKeyword,
      end_nearest,
      end_busStop
    );

    if (startAddable && endAddable) {
      addable = "both";
    } else {
      if (startAddable) {
        addable = "start";
      } else if (endAddable) {
        addable = "end";
      }
    }
    return {
      ...item,
      cardType: "result",
      ...(addable !== "" && { addable }),
    };
  });

  return addableRoute;
};

/**
 * 가장 가까운 정류장을 구한다.
 */
const getMinDistanceStop = (
  keywordPoint: URI_FORM,
  rt: API_DATA,
  stList: ISt[],
  sectionCd: "start" | "end"
) => {
  /**
   * 두 점 사이의 거리들을 담을 배열
   */
  const distArr: number[] = [];

  /**
   * 전체가 들어있는 정류장 리스트 배열에서
   * 해당하는 경로와 해당하는 sectionCd의 정류장 리스트를 가져온다
   */
  const rtStList = stList.filter(
    (st) => st.rtId === rt.rtId && st.sectionCd === sectionCd
  );

  /**
   * 정류장 리스트를 순회하며, 검색어의 출발지와 정류장과의 거리를  distArr에 담는다.
   */
  rtStList.map((st) => {
    distArr.push(
      getDistance(
        {
          lat: keywordPoint[sectionCd]!.lat,
          lng: keywordPoint[sectionCd]!.lng,
        },
        { lat: st.lat, lng: st.lng }
      )
    );
  });

  //최소 거리
  const min_distance = Math.min(...distArr);
  const idx = distArr.findIndex((distance) => distance === min_distance);

  //최소거리의 정류장을 반환한다.
  return rtStList[idx];
};

/**
 *
 * @param keywordPoint 검색지
 * @param arr 경로 리스트
 * @param stList 모든 경로의 정류장 리스트
 * @returns
 */
export const getNearestPoint = (
  keywordPoint: URI_FORM,
  arr: API_DATA[],
  stList: ISt[]
) => {
  /**
   * start: 경로 별 가장 가까운 출발지
   * end: 경로 별 가장 가까운 도착지
   */
  arr.map((rt) => {
    rt.start = getMinDistanceStop(keywordPoint, rt, stList, "start");
    rt.end = getMinDistanceStop(keywordPoint, rt, stList, "end");
    return rt;
  });
  return arr;
};

/**
 * 검색지(출발/도착)(A)로부터
 * 특정 경로의 line과 제일 가까운 점 B
 * 특정 경로의 탑승/하차지(C)
 *
 * A-B > 500m && B-C < 300m
 *
 */
export const checkBusStopAddable = (
  keywordPoint: { lat: number; lng: number }, // A 검색지
  nearestPoint: { lat: number; lng: number }, // B 가장가까운 점
  busStopCoord: { lat: number; lng: number } // C, 정류장
) => {
  const STANDARD_DIST = 0.3; //km
  const WALKING_DIST = 0.5; //km

  const A_B = getDistance(keywordPoint, nearestPoint); //검색지로부터 가장가까운 점의 거리
  const B_C = getDistance(busStopCoord, nearestPoint); // 탑승/하차지로부터 가장 가까운 경로의 점이 거리

  // 검색지로부터 가장가까운 점의 거리가 500m 미만이어야 함
  if (A_B > WALKING_DIST) return false;

  // 승/하차지로부터 가장 가까운 경로의 점의 거리가 300m 이상이어야 함
  if (B_C < STANDARD_DIST) return false;

  return true;
};
