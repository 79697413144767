import { pushDataLayer } from "src/func/tracking/gtm";
import { ISearchList } from "src/interface/pages/Search/api";
import { EvtStore } from "../stores/EvtStore";

export const impressSearchResultRt = ({ rtId, rtCd }: any) => {
  const nm = "impress_searchResult_rt";

  pushDataLayer(nm, { rt_id: rtId, rt_cd: rtCd });

  // hackleEvent(nm, "", {
  //   // rt_id: rtId,
  //   // rt_cd: rtCd,
  //   rt_id: JSON.stringify(rtId),
  //   rt_cd: JSON.stringify(rtCd),
  // });
};

export const impressSearchResultMake = (rtYn: number) => {
  const nm = "impress_searchResult_make";

  pushDataLayer(nm, { rt_yn: rtYn });
  // hackleEvent(nm, "", { rt_yn: rtYn });
};

export const evtAction = (evtStore: EvtStore, data?: ISearchList) => {
  if (data) {
    const { rtId, rtCd } = data;
    evtStore.addRtCd(rtCd);
    evtStore.addRtId(rtId);
  } else {
    // 알림받기 카드?
    evtStore.setMakeView(true);
  }
};
