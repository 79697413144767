import styled from "styled-components";
import SpotInput from "./views/SpotInput";
import { observer } from "mobx-react";

import { isNil } from "ms_data";
import { useURI } from "ms_router";
import { URI_FORM } from "src/interface/pages/Search/uri";
import { TMapStore } from "src/pages/Search/stores/TMapStore";

interface IProps {
  tmapStore: TMapStore;
}

export const Form = ({ tmapStore }: IProps) => {
  const { location, navigate, getQuery } = useURI();
  const { spotInfo } = tmapStore;
  const form = getQuery<URI_FORM>();
  const formType = location.pathname.includes("start_map") ? "start" : "end";

  const goToInput = (type: "start" | "end") => {
    navigate({
      ...location,
      pathname: `/search/${type}_form`,
      search: window.location.search,
    });
  };

  return (
    <StFakeInput>
      {formType === "end" && form.start && (
        <SpotInput
          spot={form.start}
          type={"start"}
          onClick={() => goToInput("start")}
        />
      )}
      {isNil(spotInfo) ? null : (
        <SpotInput
          spot={spotInfo}
          type={formType}
          onClick={() => goToInput(formType)}
        />
      )}
    </StFakeInput>
  );
};

export default observer(Form);

const StFakeInput = styled.section`
  width: 100%;
  background-color: var(--colors-gray-000);
  border-radius: 5px;
  box-shadow: var(--box-shadow-600);
  cursor: pointer;
`;
