import { observer } from "mobx-react";
import styled from "styled-components";

import { BackHistory } from "src/func/url";
import Input from "./views/Input";
import { Back, Button, More } from "ms-ui";
import SwiperStore from "../../../SearchResultList/SwiperStore";
import { SearchStore } from "src/pages/Search/stores/SearchStore";
import { useURI } from "ms_router";
import { URI_FORM } from "src/interface/pages/Search/uri";
import { searchresultEvtId } from "src/constants/evt/search";

interface Props {
  formType: "RESULT" | "FAKE";
  searchStore: SearchStore;
  swiperStore: SwiperStore;
  setBound: (km: 1 | 2) => void;
  changeFormType: () => void;
  setViewType: (vType: "start" | "end") => void;
}

const Form = ({
  formType,
  searchStore,
  swiperStore,
  setBound,
  changeFormType,
  setViewType,
}: Props) => {
  const { navigate, getQuery } = useURI();
  const form = getQuery<URI_FORM>();
  const { data, viewType, bound } = searchStore;
  const { activeIndex } = swiperStore;

  return (
    <>
      {formType === "FAKE" && (
        <>
          <MapBackBtn
            onClick={() => {
              BackHistory(navigate, "/");
            }}
          >
            <Back />
          </MapBackBtn>
          <StReadOnlyKeyowrdGroup>
            {form.start && (
              <Input
                dataId={"click_search_update"}
                onClick={changeFormType}
                title={form.start.title || `출발지를 입력하세요.`}
                type={"start"}
              />
            )}
            <More />
            {form.end && (
              <Input
                dataId={"click_search_update"}
                onClick={changeFormType}
                title={form.end.title || `도착지를 입력하세요.`}
                type={"end"}
              />
            )}
          </StReadOnlyKeyowrdGroup>
          <StSearchRadius>
            <div>
              <Button
                style={{
                  borderRadius: "25px",
                }}
                width={`76px`}
                size={`sm`}
                radius={"15"}
                data-id={"search_radius1km"}
                // id={searchresultEvtId.btn_1km_on.key}
                onClick={() => {
                  if (bound === 1) return;
                  setBound(1);
                }}
                outline={bound !== 1}
              >
                1km 내
              </Button>
            </div>
            <div>
              <Button
                style={{
                  borderRadius: "25px",
                }}
                width={`76px`}
                size={`sm`}
                radius={"15"}
                data-id={"search_radius2km"}
                // id={searchresultEvtId.btn_2km_on.key}
                onClick={() => {
                  if (bound === 2) return;
                  setBound(2);
                }}
                outline={bound !== 2}
              >
                2km 내
              </Button>
            </div>
            {data && data.length > 0 && activeIndex !== data.length && (
              <div style={{ width: "124px" }}>
                <Button
                  style={{
                    borderRadius: "25px",
                  }}
                  size={`sm`}
                  data-id={
                    viewType === "start" ? "search_viewEnd" : "search_viewStart"
                  }
                  // id={
                  //   searchresultEvtId[
                  //     `btn_view-${viewType === "start" ? "end" : "start"}`
                  //   ].key
                  // }
                  onClick={() =>
                    setViewType(viewType === "start" ? "end" : "start")
                  }
                  radius={"15"}
                  color={viewType === "start" ? "negative" : "positive"}
                >
                  {viewType === "start" ? "도착" : "출발"}지로 보기
                </Button>
              </div>
            )}
          </StSearchRadius>
        </>
      )}
    </>
  );
};

export default observer(Form);

const StReadOnlyKeyowrdGroup = styled.section`
  display: flex;
  align-items: center;

  z-index: 10;
  position: absolute;
  top: var(--spacing-24);
  right: var(--spacing-16);
  width: calc(100% - 88px);

  background-color: var(--colors-gray-000);
  border-radius: var(--border-radius-5);
  box-shadow: var(--box-shadow-500);
`;

const StSearchRadius = styled.div`
  position: absolute;
  right: var(--spacing-16);
  top: 86px;
  display: flex;
  /* flex-wrap: 1 1 auto; */
  width: 100%;
  height: 0;
  z-index: 10;

  justify-content: flex-end;

  div > button {
    margin-left: var(--spacing-8);
  }
`;

const MapBackBtn = styled.div`
  position: absolute;
  z-index: 1030;
  left: var(--spacing-16);
  top: calc(var(--spacing-24) + var(--spacing-4));
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--colors-gray-000);
  box-shadow: var(--box-shadow-500);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 20px;
`;
