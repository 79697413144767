import { useState } from "react";
import { observer } from "mobx-react";
import { stringifyUrl } from "src/func/url";

import styled from "styled-components";
import { Body2 } from "ms-ui";

import BusStopAddTooltip from "./views/BusStopAddTooltip";
import CustomCardNoti from "./views/CustomCard";
import SearchMatchingTooltip from "./views/SearchMatchingTooltip";
import { MSHUTTLE_PATH } from "src/constants/addr";

import SwiperStore from "./SwiperStore";

import { EvtStore } from "../../../../stores/EvtStore";

import NoResult from "./views/NoResult";
import { SearchStore } from "src/pages/Search/stores/SearchStore";
import { useURI } from "ms_router";
import { URI_FORM } from "src/interface/pages/Search/uri";
import { goMakeEvt } from "src/pages/Search/evt/GoMakeEvt";

import { evtAction } from "src/pages/Search/helper/EvtHelper";
import { ISearchList } from "src/interface/pages/Search/api";
import { goRouteEvt } from "src/pages/Search/evt/GoRouteEvt";
import { RtCard } from "./views/RtCard";
// import { Swiper, SwiperSlide } from "swiper/react";
import { hasTouchSupport } from "src/func/utils";
import { Swiper, SwiperSlide } from "src/components/Swiper/Swiper";
import { SwiperOptions } from "swiper/types";
import { TimeCnv } from "ms_data";

interface Props {
  searchStore: SearchStore;
  evtStore: EvtStore;
  swiperStore: SwiperStore;
  formType: "RESULT" | "FAKE";
}

const SearchResultList = ({
  searchStore,
  swiperStore,
  evtStore,
  formType,
}: Props) => {
  const { getQuery } = useURI();
  const { isLoading, data } = searchStore;
  const [dimmed, setDimmed] = useState(false); //흐리게

  //swiper option
  let swiperParams: SwiperOptions = {
    on: {
      _swiper(swiper: any) {
        swiperStore.setSwiper(swiper);
        evtAction(evtStore, searchStore.data?.[swiper.activeIndex]);
      },
      slideChange(s: any) {
        swiperStore.setActiveIndex(s.activeIndex);
        evtAction(evtStore, searchStore.data?.[s.activeIndex]);
      },
      progress: (s, progress) => console.log(`progress is ${progress}`),
    },

    initialSlide: swiperStore.activeIndex ?? 0,
    width: hasTouchSupport()
      ? window.innerWidth >= 768
        ? 420 - 32
        : window.innerWidth - 32
      : 420 - 32,
    spaceBetween: 8,
    resizeObserver: true,
  };

  /**
   * 경로 페이지로 이동
   */
  const goRoute = async (
    item: ISearchList,
    index: number,
    selectedIdx: number
  ) => {
    if (data && selectedIdx < data.length && item.cardType === "result") {
      searchStore.setLoading(true);
      const { rtId, rtCd, start, end } = item;
      goRouteEvt(rtId, rtCd, start.stCd, end.stCd);

      const params: any = {
        rtId: item.rtId,
        preForm: {
          startStop: item.start.stCd,
          endStop: item.end.stCd,
        },
      };

      if (index === 0) {
        params.nearest = true;
      }
      let pathName = item.rtCd === "b" ? `/running` : `/scheduled`;

      // searchStore.setLoading(false);
      window.location.href = `${MSHUTTLE_PATH.route}${pathName}${stringifyUrl(
        params
      )}`;
    }
  };

  /**
   * 알림받기 페이지로 이동
   */
  const goMake = async () => {
    searchStore.setLoading(true);
    const rt_yn = data && data.length > 0 ? 1 : 0;
    goMakeEvt(rt_yn);

    const q = getQuery<URI_FORM>();
    const realQuery = {
      startAddr: q.start?.address,
      startTitle: q.start?.title,
      startLat: Number(q.start?.lat),
      startLng: Number(q.start?.lng),
      endAddr: q.end?.address,
      endTitle: q.end?.title,
      endLat: Number(q.end?.lat),
      endLng: Number(q.end?.lng),
      timeId: q.time,
    };

    // searchStore.setLoading(false);
    window.location.href = `${MSHUTTLE_PATH.make}${stringifyUrl(realQuery)}`;
  };

  return (
    <>
      {/* 매칭 경로 있을 경우 */}
      {formType !== "RESULT" && data && data.length > 0 && (
        <>
          <StCarouselSwiper $toolTip={dimmed}>
            {/* indicator */}
            {swiperStore.activeIndex + 1 <= data.length && (
              <StIndicator>
                {swiperStore.activeIndex + 1}/{data.length}
              </StIndicator>
            )}
            <SearchMatchingTooltip setDimmed={setDimmed} />

            <Swiper
              {...swiperParams}
              injectStyles={[
                `
              .swiper-wrapper {
                padding-left: var(--spacing-16);
              }
              .swiper {
                overflow: initial;
              }
            `,
              ]}
            >
              {data.map((item, idx) => {
                const { addable } = item;
                const showTooltip =
                  swiperStore.activeIndex === idx &&
                  !dimmed &&
                  addable !== undefined;
                const id =
                  item.rtCd === "b" ? "search_runnRt" : "search_schdRt";
                return (
                  <SwiperSlide key={idx}>
                    <BusStopAddTooltip addable={addable} active={showTooltip} />
                    <div>
                      <RtCard
                        dataRtId={item.rtId}
                        dataIdx={idx}
                        dataId={id}
                        // id={id + "_" + idx + "_" + item.rtId}
                        onClick={() =>
                          goRoute(item, idx, swiperStore.activeIndex)
                        }
                        dim={dimmed && idx !== 0}
                        rt={{
                          rtCd: item.rtCd,
                          rtId: item.rtId,
                          title: `${item.startInfo} - ${item.endInfo}`,
                        }}
                        start={{
                          time: TimeCnv.secondToString(item.start.timeScnd),
                          name: item.start.alias,
                        }}
                        end={{
                          time: TimeCnv.secondToString(item.end.timeScnd),
                          name: item.end.alias,
                        }}
                        active={swiperStore.activeIndex === idx}
                        key={item.rtId}
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
              <SwiperSlide>
                <CustomCardNoti
                  active={swiperStore.activeIndex >= data.length}
                  goMake={goMake}
                />
              </SwiperSlide>
            </Swiper>
          </StCarouselSwiper>
        </>
      )}
      {/* 매칭 경로 없을 경우 */}
      {formType !== "RESULT" && !isLoading && data && data.length === 0 && (
        <>
          <StNoResultSwiper>
            <Swiper
              enabled={false}
              centeredSlides={true}
              slidesPerView={1}
              // centeredSlidesBounds={true}
              width={
                window.innerWidth > 420 ? 420 - 30 : window.innerWidth - 30
              }
              injectStyles={[
                `
              .swiper-wrapper {
                padding-left: var(--spacing-16);
              }
            `,
              ]}
            >
              <SwiperSlide>
                <NoResult goMake={goMake} evtStore={evtStore} />
              </SwiperSlide>
            </Swiper>
          </StNoResultSwiper>
        </>
      )}
    </>
  );
};

export default observer(SearchResultList);

const StIndicator = styled.div`
  ${Body2}
  position: absolute;
  z-index: unset;
  color: var(--colors-gray-500);
  max-width: 76px;
  width: 100%;
  height: 32px;
  padding: 6px var(--spacing-24);
  border-radius: var(--border-radius-15);
  box-shadow: var(--box-shadow-600);
  background-color: var(--colors-gray-000);
  left: var(--spacing-16);
  top: calc(-30px - var(--spacing-12));
`;

const StCarouselSwiper = styled.div<{ $toolTip: boolean }>`
  height: 0px;
  bottom: calc(var(--spacing-24) + 140px);
  position: absolute;
  z-index: 1057;

  swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;

    list-style: none;
    padding: 0;
    z-index: 1;

    ${(p) => p.$toolTip === true && `pointer-events: none;`}
  }

  swiper-slide {
    width: initial;

    &:only-child {
      margin-right: 0 !important;
    }

    &:first-child {
      z-index: 20;
    }

    &:last-child {
      margin-right: 0 !important;
    }

    &.dim {
      overflow: hidden;
      border-radius: 10px;

      &:nth-child(n + 2) {
        &:after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.5);
          left: 0;
          top: 0;
        }
      }
    }
  }
`;

const StNoResultSwiper = styled.div`
  z-index: 1000;
  position: absolute;
  width: 100%;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
