export const CheckSvg = ({ checked }: { checked: boolean }) => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.16675 11.0104L0.416748 6.2604L1.60425 5.0729L5.16675 8.6354L12.8126 0.989563L14.0001 2.17706L5.16675 11.0104Z"
        fill={
          checked
            ? `var(--foundation-normal-primary)`
            : `var(--contents-description)`
        }
      />
    </svg>
  );
};
