import { MSHUTTLE_DATA, MSHUTTLE_PATH } from "src/constants/addr";
import WebWorker from "src/func/worker";
import { URI_FORM } from "src/interface/pages/Search/uri";
import LogWorker from "src/pages/Search/LogWorker";

import Cookies from "js-cookie";
import { POST } from "ms-ui";

const baseURL = `${MSHUTTLE_PATH.common_resource}`;

class searchRepository {
  getPathListByForm(data: URI_FORM, bound: number) {
    return POST(
      {
        baseURL: baseURL,
        url: "/search",
      },
      {
        start: data.start,
        end: data.end,
        time: data.time,
        search: data.search,
        sd: bound,
        ed: bound,
      }
    );
  }

  insertLog = async (
    data: URI_FORM,
    bound: number,
    runn_cnt: number,
    schd_cnt: number
  ) => {
    return new Promise((rs) => {
      const workerInstance: WebWorker = new WebWorker(LogWorker);
      workerInstance.worker.onmessage = (e: any) => {
        rs(e.data.res);
      };

      workerInstance.worker.postMessage({
        on: true,
        customHeader: {
          "ms-front-version": MSHUTTLE_DATA.ver,
          "ms-front-stage": MSHUTTLE_DATA.stage,
          "ms-front-referrer": window.location.href,
        },
        url: `${baseURL}/search/log`,
        data: {
          start: data.start,
          end: data.end,
          time: data.time,
          search: data.search,
          sd: bound,
          ed: bound,
          runn_cnt,
          schd_cnt,
          token: Cookies.get("MSUW_SES") || null,
          gid: Cookies.get("_gid") || null,
          ga: Cookies.get("_ga") || null,
        },
      });
    });
  };
}

export default new searchRepository();
