import { contents } from "ms-ui";
import styled from "styled-components";

export const SDKLoadFailed = () => {
  return (
    <NoMap>
      <div>
        <p>지도를 불러올 수 없습니다.</p>
        <p>*사내 wifi 연결 해제 및 에드블럭 해제 필수</p>
      </div>
    </NoMap>
  );
};

const NoMap = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${contents.description.value};
  border-bottom: 1px solid #e9ebee;
  font-size: 1.6rem;
  p {
    text-align: center;
  }
  span {
    margin-bottom: 15px !important;
    margin: 0 auto;
  }
`;
