import { useEffect, useState } from "react";

import SearchZone from "./views/SearchZone";
import SearchResultList from "./views/SearchResultList";

import SwiperStore from "./views/SearchResultList/SwiperStore";
import { EvtStore } from "../../stores/EvtStore";

import Map from "./views/Map";
import { SearchStore } from "../../stores/SearchStore";
import { observer } from "mobx-react";

import { KMapStore, ModalContainer } from "ms-ui";
import { ModalLoader } from "ms-ui";
import ExitHandler from "./ExitHandler";
import { useURI } from "ms_router";
import { URI_FORM } from "src/interface/pages/Search/uri";
import styled from "styled-components";

interface Props {
  searchStore: SearchStore;
}

const SearchResult = ({ searchStore }: Props) => {
  const { getQuery } = useURI();
  const qs = getQuery<URI_FORM>();
  console.log("qs: ", qs);

  const { data: searchResult } = searchStore;

  const [mapStore] = useState(() => new KMapStore());
  const [swiperStore] = useState(() => new SwiperStore(qs));
  const [evtStore] = useState(() => new EvtStore());
  const [formType, setFormType] = useState<"RESULT" | "FAKE">("FAKE");

  /**
   * search
   */
  useEffect(() => {
    searchStore.search();
  }, []);

  /**
   * data가 바뀌거나
   * activeIndex가 바뀌거나
   *
   * 카카오맵 center를 변경
   */
  useEffect(() => {
    const { activeIndex } = swiperStore;
    console.log("activeIndex: ", activeIndex);

    if (searchResult && searchResult.length === 0) {
      let point1: any, point2: any;
      if (qs["start"]?.lat && qs["start"]?.lng) {
        point1 = [+qs["start"]!.lat, +qs["start"]!.lng];
      }
      if (qs["end"]?.lat && qs["end"]?.lng) {
        point2 = [+qs["end"]!.lat, +qs["end"]!.lng];
      }

      if (!point1 || !point2) return;

      mapStore.setBoundsByPoints(
        [
          [point1[0], point1[1]],
          [point2[0], point2[1]],
        ],
        [200, 32, 210, 32],
        false
      );
    } else {
      setBounds();
    }
  }, [searchResult, swiperStore.activeIndex]);

  /**
   * activeMarker의 좌표점을 중심으로 카카오맵 center를 변경
   */
  const setBounds = () => {
    const { activeIndex } = swiperStore;
    const { data, viewType } = searchStore;
    if (!data) return;
    if (activeIndex >= data.length) return;
    let point1: any, point2: any;
    point1 = [
      +data[activeIndex][viewType].lat,
      +data[activeIndex][viewType].lng,
    ];

    if (qs[viewType]?.lat && qs[viewType]?.lng) {
      point2 = [+qs[viewType]!.lat, +qs[viewType]!.lng];
    }

    if (!point1 || !point2) return;

    mapStore.setBoundsByPoints(
      [
        [point1[0], point1[1]],
        [point2[0], point2[1]],
      ],
      [200, 32, 210, 32],
      false
    );
  };

  return (
    <StModalContainer>
      {/* 페이지 이탈 시 이벤트 보내기 */}
      <ExitHandler
        searchStore={searchStore}
        swiperStore={swiperStore}
        evtStore={evtStore}
      />
      {/* loader */}
      <ModalLoader active={searchStore.isLoading} />

      {/* top */}
      <SearchZone
        mapStore={mapStore}
        searchStore={searchStore}
        swiperStore={swiperStore}
        formType={formType}
        setFormType={setFormType}
      />

      {/* map */}
      <Map
        mapStore={mapStore}
        searchStore={searchStore}
        swiperStore={swiperStore}
        formType={formType}
      />

      {/* swiper */}
      <SearchResultList
        searchStore={searchStore}
        swiperStore={swiperStore}
        evtStore={evtStore}
        formType={formType}
      />
    </StModalContainer>
  );
};

export default observer(SearchResult);

const StModalContainer = styled(ModalContainer)`
  overflow: hidden;
`;
